import React from "react";
import { TradingChart } from "src/pages/TradingChart";
import { useTheme, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { limitDecimals, strTrunc } from "src/global/utils";
import { createClient } from "@supabase/supabase-js";
import { StrategyContext } from "src/global/contexts";
import { SUPABASE_PROJECT_API_KEY, SUPABASE_PROJECT_URL } from "src/global/constants";

// Taken from https://mui.com/material-ui/react-table/#customization
interface IProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

const defaultProps: IProps = {
  count: 10,
  page: 1,
  rowsPerPage: 10,
  onPageChange: (a, b) => console.warn("onPageChange not bound in SignalsTable")
};

function SignalsTablePaginationActions(props = defaultProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    background: "transparent",
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const chartProps = {
  data: [],
  colors: {
    backgroundColor: "white",
    lineColor: "#2962FF",
    textColor: "black",
    areaTopColor: "#2962FF",
    areaBottomColor: "rgba(41, 98, 255, 0.28)"
  }
};

export interface IRow {
  created_at: string;
  external_price: number;
  gas_cost: number;
  hashes: string[];
  id: number;
  num_swaps: number;
  order_num: number;
  outcome: number;
  pct_to_trade: number;
  signal: string;
  strategy_num: number;
  swap_price: number;
  use_0x: boolean;
};

const supabase = createClient(SUPABASE_PROJECT_URL, SUPABASE_PROJECT_API_KEY);

export function SignalsTable() {
  const { selectedStrategy } = React.useContext(StrategyContext);

  const [rows, setRows] = React.useState<IRow[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    console.log(selectedStrategy);
    getLatestTrades();
  }, [selectedStrategy.num]);

  async function getLatestTrades() {
    // New strategyId
    if (rows.length && rows[0].strategy_num !== selectedStrategy.num) {
      setRows([]);
    }

    const { data, error } = await supabase.from("Signals").select("*").range(0, 100).eq("strategy_num", selectedStrategy.num).order("created_at", { ascending: false });

    if (error) {
      console.error(error);
      return;
    }

    for (const row of data) {
      switch (row.order_num) {
        case 1:
          row.signal = "Long Entry";
          break;
        case 2:
          row.signal = "Long Exit";
          break;
        case -1:
          row.signal = "Short Entry";
          break;
        case -2:
          row.signal = "Short Exit";
          break;
      }
    }

    setRows(data);
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="etx-main">
      <div className="etx-main__body">
        <TradingChart data={chartProps.data} colors={chartProps.colors} rows={rows} />
        <div className="tableWrapper">
          <TableContainer component={Paper} style={{ background: "rgba(5, 2, 24, 0.4)", borderRadius: "10px" }}>
            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>TRADE DATE</StyledTableCell>
                  <StyledTableCell align="center">DIRECTION</StyledTableCell>
                  <StyledTableCell align="right">PRICE</StyledTableCell>
                  <StyledTableCell align="right">OUTCOME</StyledTableCell>
                  <StyledTableCell align="right">TX HASH</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows
                ).map(row => (
                  <TableRow key={row.created_at + "_" + row.id}>
                    <TableCell component="th" scope="row">
                      {new Date(row.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      {row.signal}
                    </TableCell>
                    <TableCell align="right">
                      {limitDecimals(row.swap_price)}
                    </TableCell>
                    <TableCell align="right" style={{ color: row.outcome >= 0 ? "var(--color-green-1)" : "var(--color-red-2)" }}>
                      {row.outcome ? (row.outcome >= 0 ? "+" : "") + row.outcome + "%" : ""}
                    </TableCell>
                    <TableCell align="right" style={{ width: "28%" }}>
                      {
                        row.hashes?.[0]
                          ? <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://snowtrace.io/tx/${row.hashes[0]}`}
                            style={{
                              textDecoration: "none",
                              fontFamily: "monospace"
                            }}>
                            {strTrunc(row.hashes[0], 7, 7)}
                          </a>
                          : <></>
                      }

                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              <TableFooter>
                <TableRow style={{ border: "none" }}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    colSpan={4}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      select: {
                        inputProps: {
                          "aria-label": "rows per page"
                        },
                        native: true
                      }
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={SignalsTablePaginationActions}
                    style={{ border: "none" }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}