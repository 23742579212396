import React from "react";
import { ContractsManagerContext, FunctionsContext, NetworkContext, StrategyContext, SystemSummaryContext } from "src/global/contexts";
import { BigNumber, displayPct, limitDecimals } from "src/global/utils";
import { BASE_LEARN_MORE_URL } from "src/global/constants";
import { IBalances } from "src/pages/trade/Sidebar";
import { TradeStatus } from "src/pages/strategy/misc/TradeStatus";

interface IProps {
  layout: "strategy" | "trade";
  balances?: IBalances;
  totalCashValue?: number;
}

const defaultProps: IProps = {
  layout: "strategy",
  totalCashValue: 0
};

export function Info(props = defaultProps) {
  const { SystemSummary } = React.useContext(SystemSummaryContext);
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { showDialog } = React.useContext(FunctionsContext);
  const { selectedStrategy } = React.useContext(StrategyContext);
  const { network } = React.useContext(NetworkContext);

  function showStrategyDescription() {
    const titleHtml = (
      <div>
        <p className="font-h4" style={{ textAlign: "center", fontVariantCaps: "small-caps" }}>{selectedStrategy.name}</p>
        <div className="etx-card__item Card__item ph-0 pv-0">
          <hr />
        </div>
        <br />
      </div>
    );

    // ``descriptions[selectedStrategy.id].cash`` is another option
    if (selectedStrategy.description.cash !== SystemSummary.Strategy.cashSymbol) {
      console.warn(
        "selectedStrategy.description.cash DOES NOT MATCH SystemSummary.Strategy.cashSymbol in Strategy Info Sidebar:",
        selectedStrategy.description.cash,
        SystemSummary.Strategy.cashSymbol
      );
    }

    if (selectedStrategy.description.asset !== SystemSummary.Strategy.assetSymbol) {
      console.warn(
        "selectedStrategy.description.asset DOES NOT MATCH SystemSummary.Strategy.assetSymbol in Strategy Info Sidebar:",
        selectedStrategy.description.asset,
        SystemSummary.Strategy.assetSymbol
      );
    }

    const contentHtml = (
      <div>
        <div style={{ textAlign: "left" }}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td><b>Cash Token:</b></td>
                <a
                  href={network.blockExplorerForTokenURL + SystemSummary.Strategy.cash}
                  target="_blank"
                >
                  <td>{SystemSummary.Strategy.cashSymbol}</td>
                </a>
              </tr>
              <tr>
                <td><b>Asset Token:</b></td>
                <a
                  href={network.blockExplorerForTokenURL + SystemSummary.Strategy.asset}
                  target="_blank"
                >
                  <td>{SystemSummary.Strategy.assetSymbol}</td>
                </a>
              </tr>
              <tr>
                <td><b>Trader Type:</b></td>
                <td>{selectedStrategy.description.traderType}</td>
              </tr>
              <tr>
                <td><b>Supervisor:</b></td>
                <td>{selectedStrategy.description.supervisor}</td>
              </tr>
              <tr>
                <td><b>Base Profit Share:</b></td>
                <td>{displayPct(SystemSummary.Controller.settings.pctProfitShare, 2).toFixed(2)}%</td>
              </tr>
              <tr>
                <td><b>Category:</b></td>
                <td>{selectedStrategy.description.category}</td>
              </tr>
            </tbody>
          </table>
          <br />
          {selectedStrategy.description.info?.map((x) => <div><p>{x}</p></div>) ?? <></>}
          <br />
          <p>
            <a
              href={BASE_LEARN_MORE_URL + selectedStrategy.num}
              target="_blank"
            >
              Click here to learn more
            </a>
          </p>
        </div>
      </div>
    );

    showDialog(titleHtml, contentHtml);
  }

  return (
    <div className="etx-card Card bg-white-1-20 border-radius overflow-hidden">
      <div className="etx-card__item Card__item pv-50 bg-white-1-20">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-center">
              <h5>Strategy&nbsp;&nbsp;Info</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50 strategyInfo_hover" onClick={showStrategyDescription}>
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">Name</p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {
                  SystemSummary.Strategy.strategyInfo1
                    ? SystemSummary.Strategy.strategyInfo1
                    : "None"
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50 strategyInfo_hover" onClick={showStrategyDescription}>
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">Supervisor</p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {
                  SystemSummary.Strategy.strategyInfo2
                    ? SystemSummary.Strategy.strategyInfo2.split("Managed by ")[1]
                    : "None"
                }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <div className="etx-card__item Card__item pv-50 strategyInfo_hover" onClick={showStrategyDescription}>
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">Trader Type</p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-large font-weight-medium">
                {selectedStrategy.description.traderType}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      {
        props.layout === "trade"
          ? // Layout specific to the Trade page
          <>
            <div className="etx-card__item Card__item pv-50 strategyInfo_hover" onClick={showStrategyDescription}>
              <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">{contractManager.cash.name} Now</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <p className="font-size-large font-weight-medium">
                      {Number(
                        BigNumber(props.totalCashValue ?? 0)
                          .mul(1000)
                          .div(contractManager.cash.denominator)
                      ) / 1000
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
          : // Layout specific to the Strategy page (default layout)
          <>
            <div className="etx-card__item Card__item pv-50">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                <div className="etx-flex__item Flex__item shrink-0" title="Receive a 1% reduction of the Profit Fee for every Ŧ20,000 deposited.">

                  <div className="etx-text Text">
                    <p className="font-label">Profit Fee % <i className="far fa-info-circle"></i></p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <p className="font-size-large font-weight-medium">
                      {displayPct(SystemSummary.Controller.ownerInfoCurrent.pctProfitShare)}%
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item ph-0 pv-0">
              <hr />
            </div>

            <div className="etx-card__item Card__item pv-50">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">Subscription Fee Per Month</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <p className="font-size-large font-weight-medium">
                      <span style={{ fontSize: "0.95em" }}>Ŧ</span>{
                        limitDecimals(SystemSummary.currentContract.subscription.feePerMonth.toNumber() / contractManager.contracts.TE.denominator.toNumber())
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item ph-0 pv-0">
              <hr />
            </div>

            <div className="etx-card__item Card__item pv-50">
              <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">Subscription Paid Thru</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <p className="font-size-large font-weight-medium">
                      {
                        SystemSummary.currentContract.subscription.paidThru.gt(0)
                          ? new Date(SystemSummary.currentContract.subscription.paidThru.toNumber() * 1000).toLocaleDateString()
                          : "N/A"
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
      }

      <div className="etx-card__item Card__item ph-0 pv-0">
        <hr />
      </div>

      <TradeStatus
        fieldName="Current Trade"
        balances={props.balances && props.balances.totalCashValue > 0 ? props.balances : undefined}
        depositWithdrawFunctionsForStrategy={props.balances && props.balances.totalCashValue > 0 ? undefined : contractManager.contracts.DepositWithdraw.functions}
      />

    </div>
  );
}
