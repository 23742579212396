import { createChart, WhitespaceData, AreaData, CrosshairMode, SeriesMarkerPosition, SeriesMarkerShape, ISeriesApi, CandlestickData, UTCTimestamp } from "lightweight-charts";
import React from "react";
import { IRow } from "src/pages/SignalsTable";
import { IMessageEvent, w3cwebsocket } from "websocket";

export interface IProps {
    data: (AreaData | WhitespaceData)[];
    colors: {
        backgroundColor: string,
        lineColor: string,
        textColor: string,
        areaTopColor: string,
        areaBottomColor: string;
    };
    rows: IRow[];
};

export interface ICoinbaseData {
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
};

export interface CoinbaseWSMessage {
    type: string;
    product_id: string;
    changes: [string, string, string][];
    time: string;
}

// TODO: pull from contracts.asset.coinbaseName and contracts.cash.coinbaseName
const PAIR = "BTC-USD";
const GRANULARITY = 21600;
const EARLIEST_DATE = new Date("2023-06-20T04:00:00");
const MAX_CANDLES = 300;
const MS_PER_SEC = 1000;

const forwardDate = (date: Date, msForward: number) => {
    return new Date(date.getTime() + msForward);
};

const minDate = (date1: Date, date2: Date) => {
    return date1 < date2 ? date1 : date2;
};

const fetchCandles = async (pair: string, granularity: number, start: Date, end: Date): Promise<CandlestickData[]> => {
    const candlesResponse = await fetch(`https://api.exchange.coinbase.com/products/${pair}/candles?granularity=${granularity}&start=${start.toISOString()}&end=${end.toISOString()}`);
    const candles: ICoinbaseData[] = await candlesResponse.json();
    return candles.map(candle => {
        return {
            time: candle[0] as UTCTimestamp,
            low: candle[1],
            high: candle[2],
            open: candle[3],
            close: candle[4],
            volume: candle[5]
        };
    });
};

const fetchCandlesUntil = async (pair: string, granularity: number, start: Date) => {
    let candles: CandlestickData[] = [];
    let count = 0;

    let now = new Date();
    let end = start;

    do {
        end = minDate(forwardDate(end, granularity * MS_PER_SEC * MAX_CANDLES), now);
        candles = [...new Set(candles), ...new Set(await fetchCandles(pair, granularity, start, end))];
        start = forwardDate(start, granularity * MS_PER_SEC * MAX_CANDLES);
    } while (end < now && count++ <= 10);

    return candles.sort((a, b) => Number(a.time) - Number(b.time));
};

// Function to calculate Heikin Ashi candles from regular candles
const calculateHeikinAshiCandles = (candles: CandlestickData[]) => {
    const heikinAshiCandles: CandlestickData[] = [];

    let haOpen: number, haHigh: number, haLow: number, haClose: number;
    haOpen = (candles[0].open + candles[0].close) / 2;

    for (let i = 0; i < candles.length; i++) {
        haClose = (candles[i].open + candles[i].high + candles[i].low + candles[i].close) / 4;
        haHigh = Math.max(candles[i].high, haOpen, haClose);
        haLow = Math.min(candles[i].low, haOpen, haClose);

        heikinAshiCandles.push({
            time: candles[i].time,
            open: haOpen,
            high: haHigh,
            low: haLow,
            close: haClose
        });

        if (i < candles.length - 1) {
            haOpen = (haOpen + haClose) / 2;
        }
    }

    return heikinAshiCandles;
};

function getDefaultCandle(): CandlestickData {
    return {
        time: Date.now() as UTCTimestamp,
        open: 0,
        high: Number.NEGATIVE_INFINITY,
        low: Number.POSITIVE_INFINITY,
        close: 0
    };
}

// Purposefully not in state to keep this data from resetting between component reloads
const latestCandle = getDefaultCandle();
// console.log(latestCandle);

export function TradingChart(props: IProps) {
    const [priceSeries, setPriceSeries] = React.useState<ISeriesApi<"Candlestick">>();
    const [websocket, setWebsocket] = React.useState<w3cwebsocket>();
    const [websocketMessage, setWebsocketMessage] = React.useState<IMessageEvent>();
    const [websocketMessages, setWebsocketMessages] = React.useState<CoinbaseWSMessage[]>([]);
    const chartContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!chartContainerRef.current) {
            console.log("No chartContainerRef current in useEffect()!");
            return;
        }

        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth - 50,
            height: 400,
            layout: {
                textColor: "#FFFFFF",
                background: { color: "transparent" }
            },
            grid: {
                vertLines: {
                    color: "transparent"
                },
                horzLines: {
                    color: "transparent"
                }
            },
            crosshair: {
                mode: CrosshairMode.Normal
            },
            rightPriceScale: {
                borderColor: `#FFFFFF`,
                textColor: `#FFFFFF`
            },
            // https://lightweight-charts-docs.netlify.app/docs/time-scale
            timeScale: {
                borderColor: `#FFFFFF`,
                rightOffset: 5,
                timeVisible: true,
                secondsVisible: true,
            }
        });

        chart.timeScale().fitContent();

        const upColour = "rgba(24, 182, 201, 0.7)"; // https://www.color-hex.com/color/18b6c9
        const dnColour = "rgba(201, 103, 33, 0.7)"; // https://www.color-hex.com/color/c96721

        setPriceSeries(
            chart.addCandlestickSeries({
                upColor: upColour,
                downColor: dnColour,
                borderUpColor: upColour,
                borderDownColor: dnColour,
                wickUpColor: upColour,
                wickDownColor: dnColour
            })
        );

        const newSeries = chart.addAreaSeries({
            lineColor: props.colors.lineColor,
            topColor: props.colors.areaTopColor,
            bottomColor: props.colors.areaBottomColor
        });
        newSeries.setData(props.data);

        const handleResize = () => {
            if (!chartContainerRef.current) {
                console.log("No chartContainerRef current in handleResize()!");
                return;
            }

            chart.applyOptions({ width: chartContainerRef.current.clientWidth - 50 });
        };

        const _websocket = new w3cwebsocket("wss://ws-feed.exchange.coinbase.com");
        const _websocketRequest = {
            "type": "subscribe",
            "product_ids": [PAIR],
            "channels": ["level2_batch"]
        };

        _websocket.onopen = () => _websocket.send(JSON.stringify(_websocketRequest));
        _websocket.onmessage = message => setWebsocketMessage(message);

        setWebsocket(_websocket);
        window.addEventListener("resize", handleResize);
        return () => {
            if (websocket) {
                websocket.close();
            }
            window.removeEventListener("resize", handleResize);
            chart.remove();
        };
    }, []);

    React.useEffect(() => {
        if (!priceSeries) {
            console.log("priceSeries is undefined");
            return;
        }

        fetchCandlesUntil(PAIR, GRANULARITY, EARLIEST_DATE)
            .then(candles => {
                // Don't reload the page on production
                if (process.env.NODE_ENV === "development") {
                    // Terribly hacky
                    if (!(priceSeries as any)._private__priceScaleApiProvider._private__timeScaleApi._private__timeAxisWidget._private__canvasBinding._canvasElement) {
                        // Refresh the page
                        window.location.reload();
                        // console.error("Object is disposed");
                        return;
                    }
                }

                // console.log(candles);

                // Newest candle opens at the oldest candle's close
                const _latestCandle = candles.at(-1);
                if (_latestCandle) {
                    latestCandle.open = (_latestCandle.high + _latestCandle.low) / 2;
                }

                priceSeries.setData(calculateHeikinAshiCandles(candles));
                priceSeries.setMarkers(getSignalMarkers().reverse());

                // Only push candle to chart if it has been updated at least once from the websocket
                if (latestCandle.high !== Number.NEGATIVE_INFINITY && latestCandle.low !== Number.POSITIVE_INFINITY) {
                    priceSeries.update(latestCandle);
                }
            })
            .catch(error => console.error(error));
    }, [props.rows]);

    React.useEffect(() => {
        if (!websocketMessage) {
            console.log("websocketMessage is undefined");
            return;
        }

        if (typeof websocketMessage.data === "string") {
            setWebsocketMessages([...websocketMessages, JSON.parse(websocketMessage.data)]);
        }
        else if (
            "type" in websocketMessage.data
            && "product_id" in websocketMessage.data
            && "changes" in websocketMessage.data
            && "time" in websocketMessage.data
        ) {
            setWebsocketMessages([...websocketMessages, websocketMessage.data as CoinbaseWSMessage]);
        }
    }, [websocketMessage]);

    React.useEffect(() => {
        if (priceSeries && websocketMessages.length >= 20) {
            const threeHrs = 3 * 3600 * 1000;
            const now = Date.now();
            const timeSinceLastThreeHrs = now % threeHrs;

            let sum = 0;
            let num = 0;

            websocketMessages.forEach(data => {
                if (data.type !== "l2update") {
                    return;
                }

                data.changes.forEach(change => {
                    const price = Number(change[1]);

                    // Remove outliers
                    if (latestCandle.high !== Number.NEGATIVE_INFINITY && latestCandle.low !== Number.POSITIVE_INFINITY) {
                        if (price > latestCandle.high * 1.1 || price < latestCandle.low * 0.9) {
                            return;
                        }
                    }

                    sum += price;
                    num++;
                });
            });

            latestCandle.time = Math.floor((now - timeSinceLastThreeHrs + threeHrs) / 1000) as UTCTimestamp; // Every 3hrs

            const price = sum / num;

            // Fake the initial high
            if (latestCandle.high === Number.NEGATIVE_INFINITY) {
                latestCandle.high = price * 1.005;
            }
            else if (price > latestCandle.high) {
                latestCandle.high = price;
            }

            // Fake the initial low
            if (latestCandle.low === Number.POSITIVE_INFINITY) {
                latestCandle.low = price * 0.995;
            }
            else if (price < latestCandle.low) {
                latestCandle.low = price;
            }

            latestCandle.close = price;

            // console.log("latestCandle.open", latestCandle.open);
            // console.log("latestCandle.close", latestCandle.close);

            priceSeries.update(latestCandle);
            setWebsocketMessages([]);
        }
    }, [websocketMessages]);

    function getSignalMarkers() {
        return props.rows.map(row => {
            let position: SeriesMarkerPosition, color: string, shape: SeriesMarkerShape, text: string;

            const longPosition = "belowBar";
            const shortPosition = "aboveBar";
            const entryColour = "white";
            const exitColour = "#AAAAAA";

            // https://www.tradingview.com/lightweight-charts/
            // https://tradingview.github.io/lightweight-charts/docs/api#seriesmarkershape
            switch (row.order_num) {
                case 1:
                    position = longPosition;
                    color = entryColour;
                    shape = "arrowUp";
                    text = row.signal;
                    break;
                case 2:
                    position = longPosition;
                    color = exitColour;
                    shape = "circle";
                    text = row.signal;
                    break;
                case -1:
                    position = shortPosition;
                    color = entryColour;
                    shape = "arrowDown";
                    text = row.signal;
                    break;
                case -2:
                    position = shortPosition;
                    color = exitColour;
                    shape = "circle";
                    text = row.signal;
                    break;
                default:
                    throw new Error(`Invalid order_num ${row.order_num} in Supabase row ${row.id}`);
            }

            return {
                time: new Date(row.created_at).toLocaleDateString("EN-CA"),
                position,
                color,
                shape,
                text,
                id: `${row.created_at}-${row.order_num}`
            };
        });
    }

    return (
        <div className="etx-container Container pt-50 pb-0" style={{ paddingTop: "0px" }}>
            <div className="etx-center">
                <div className="etx-card Card chartColour border-radius" >
                    <div
                        id="chart_container"
                        className="etx-card__item Card__item ph-200 pv-200"
                        ref={chartContainerRef}
                    >
                    </div>
                </div>
            </div>
        </div>
    );
}