import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import React from "react";
import { ThreeDots } from "react-loader-spinner";
import { Route, Routes } from "react-router-dom";
import { Config, WagmiConfig, configureChains, createConfig } from "wagmi";
import { avalanche, arbitrum, localhost } from "wagmi/chains";
import { consoleLogOnLoad } from "src/ABIs/consoleLogOnLoad";
import { ContractManager, IContract, ITokenContract } from "src/ABIs/contract_map";
import "src/assets/fonts/fontawesome.css";
import "src/assets/styles/style.css";
import { DialogTemplate, IDialogAction, IDialogMessage, defaultDialogMessage } from "src/pages/DialogTemplate";
import { FaqOverlay } from "src/pages/FaqOverlay";
import { Page as FcaPage } from "src/pages/fca/Page";
import { Page as SwapPage } from "src/pages/swap/Page";
import { ISwapData } from "src/pages/swap/content/Control";
import { Page as TradePage } from "src/pages/trade/Page";
import { Page as RootPage } from "src/pages/strategy/Page";
import { TUTORIAL_URL, projectId, INetwork, NETWORKS, defaultNetwork } from "src/global/constants";
import {
  DepositWithdrawDialogTab,
  DialogState,
  IWallet,
  IWalletBalances,
  ISystemSummaryObj,
  defaultWallet,
  defaultSystemSummary,
  IRPCError,
  ConnectionState,
  ERC2612PermitMessage_RSV,
  isRPCError
} from "src/global/types";
import { BigNumber, fixSolidityStruct } from "src/global/utils";
import {
  WalletContext,
  OverlayType,
  ContractsManagerContext,
  FunctionsContext,
  SystemSummaryContext,
  StrategyContext,
  LoaderContext,
  AcceptedFCAContext,
  NetworkContext,
  DialogContext,
  SwapFunctionsContext
} from "src/global/contexts";
import { flushSync } from "react-dom";
import { ethers } from "ethers";
import { signERC2612Permit } from "eth-permit";
import { DEFAULT_STRATEGY_INDEX, IStrategy, strategies } from "src/global/strategies";
import { Page as MembershipPage } from "./pages/memberships/Page";

// replace console.* to disable logging for production
if (process.env.NODE_ENV === "production") {
  console.log = () => { };
  console.info = () => { };
  console.warn = () => { };
  console.error = () => { };
  console.debug = () => { };
}

console.log(consoleLogOnLoad);

export interface IAppState {
  contractManager: ContractManager;
  SystemSummary: ISystemSummaryObj;
  wallet: IWallet;
  network: INetwork;
  dialogState: DialogState;
  dialogTab: DepositWithdrawDialogTab;
  isLoading: boolean;
  acceptedFcaNotice?: boolean;
  dialogMessage: IDialogMessage;
  activeOverlay: OverlayType;
  selectedStrategy: IStrategy;
};

const _selectedStrategy = localStorage.getItem("selectedStrategy");

export const initialAppState: IAppState = {
  contractManager: new ContractManager(defaultNetwork),
  SystemSummary: defaultSystemSummary,
  wallet: defaultWallet,
  network: defaultNetwork,
  dialogState: "CLOSED",
  dialogTab: "DEPOSIT",
  isLoading: false,
  acceptedFcaNotice: undefined,
  dialogMessage: defaultDialogMessage,
  activeOverlay: null,
  selectedStrategy: _selectedStrategy ? JSON.parse(_selectedStrategy) : strategies[DEFAULT_STRATEGY_INDEX]
};

const chains = [avalanche, arbitrum, { ...localhost, id: 31337 }];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// TODO UPDATE THE `manifest.json`
// https://dev.to/arshadyaseen/easily-update-your-web-apps-manifest-file-with-react-manifest-npm-library-3hae
// const manifestDetails = {
//   "name": "Trendespresso App",
//   "short_name": "TE App",
//   "start_url": "underConstruction.html",
//   "display": "standalone",
//   "orientation": "portrait",
//   "theme_color": "#000000",
//   "background_color": "#ffffff",
//   "icons": [
//     {
//       "src": "icon-192x192.png",
//       "sizes": "192x192"
//     },
//     {
//       "src": "icon-512x512.png",
//       "sizes": "512x512"
//     }
//   ],


//   // And More...

// };

type AppState = Readonly<typeof initialAppState>;

class App extends React.Component<any, IAppState> {
  state: AppState = initialAppState;

  provider?: ethers.providers.Web3Provider;
  wagmiConfig: Config;
  ethereumClient: EthereumClient;

  constructor(props: any) {
    super(props);

    this.wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({ projectId, chains }),
      publicClient
    }) as Config;

    this.ethereumClient = new EthereumClient(this.wagmiConfig, chains);
  }

  render() {
    const {
      isLoading,
      dialogMessage,
      activeOverlay,
      wallet,
      network,
      contractManager,
      SystemSummary,
      selectedStrategy,
      acceptedFcaNotice,
      dialogState,
      dialogTab
    } = this.state;

    return (
      <React.Fragment>
        {isLoading ? (
          <ThreeDots
            height="90"
            width="90"
            radius="6"
            color="#4bc9c8"
            ariaLabel="three-dots-loading"
            visible={isLoading}
            wrapperClass="Loader"
          />
        ) : (
          <></>
        )}

        <div className={dialogMessage || activeOverlay ? "etx-app-outer overlay-show" : "etx-app-outer"}>
          <WagmiConfig config={this.wagmiConfig}>
            <AcceptedFCAContext.Provider value={{
              acceptedFcaNotice,
              onFcaNoticeAccepted: this.onFcaNoticeAccepted
            }}>
              <LoaderContext.Provider value={{ isLoading }}>
                <WalletContext.Provider value={{ wallet }}>
                  <NetworkContext.Provider value={{ network }}>
                    <DialogContext.Provider value={{ dialogState, dialogTab }}>
                      <ContractsManagerContext.Provider value={{ contractManager }}>
                        <SystemSummaryContext.Provider value={{ SystemSummary }}>
                          <FunctionsContext.Provider value={{
                            tryTx: this.tryTx,
                            approve: this.approve,
                            createPermit: this.createPermit,
                            showErrorMessage: this.showErrorMessage,
                            showDialog: this.showDialog,
                            setActiveOverlay: this.setActiveOverlay,
                            toggleDepositWithdrawDialog: this.toggleDepositWithdrawDialog,
                            switchDepositWithdrawDialogTab: this.switchDepositWithdrawDialogTab,
                            subscribeToBlockchain: this.subscribeToBlockchain,
                            unsubscribeToBlockchain: this.unsubscribeToBlockchain,
                            setWalletConnectionState: this.setWalletConnectionState,
                            setLoader: this.setLoader
                          }}>
                            <SwapFunctionsContext.Provider value={{
                              getTokenApprovedAmount: this.getTokenApprovedAmount,
                              getTokenBalance: this.getTokenBalance,
                              swapTokenHolder: this.swapTokenHolder,
                              swap: this.swap
                            }}>
                              <StrategyContext.Provider value={{
                                selectedStrategy,
                                setStrategy: this.setStrategy,
                              }}>
                                {this.routes()}
                              </StrategyContext.Provider>
                            </SwapFunctionsContext.Provider>
                          </FunctionsContext.Provider>
                        </SystemSummaryContext.Provider>
                      </ContractsManagerContext.Provider>
                    </DialogContext.Provider>
                  </NetworkContext.Provider>
                </WalletContext.Provider>
              </LoaderContext.Provider>
            </AcceptedFCAContext.Provider>
          </WagmiConfig>

          <Web3Modal projectId={projectId} ethereumClient={this.ethereumClient} />
        </div>
      </React.Fragment>
    );
  }

  routes() {
    const { dialogMessage, activeOverlay, acceptedFcaNotice } = this.state;

    return (
      <>
        <Routes>
          <Route path="/" element={acceptedFcaNotice ? <RootPage /> : <FcaPage />} />
          <Route path="/swap" element={<SwapPage />} />
          <Route path="/memberships" element={<MembershipPage />} />
          <Route path="/trade" element={<TradePage />} />
        </Routes>

        {dialogMessage.isOpen ? <DialogTemplate message={dialogMessage} /> : <></>}
        {activeOverlay === "FAQ" ? <FaqOverlay /> : <></>}
      </>
    );
  }

  componentDidMount() {
    // This subscription is called on any `wagmiConfig` state change
    this.wagmiConfig.subscribe(
      state => state,
      async state => {
        // Wallet disconnected
        if (state.status === "disconnected") {
          this.unsubscribeProvider();
          this.setState({ wallet: defaultWallet });
          return;
        }

        // Not yet ready to connect
        if (!state.data?.account) {
          return;
        }

        try {
          // @ts-ignore
          flushSync(() => this.setWalletConnecting(state.data.account));
          this.unsubscribeProvider();
          this.connectToEth();

          const chainId = (await this.provider?._networkPromise)?.chainId;
          if (!chainId || !Object.keys(NETWORKS).includes(String(chainId))) {
            this.showDialog(
              "Invalid Blockchain",
              <div className="font-size-medium">
                <br />
                Please connect to one of the following blockchains:
                <br />
                {Object.values(NETWORKS).map((n) => {
                  return (
                    Number(n.chainId) === 31337
                      ? <></>
                      : <div className="font-weight-medium" style={{ cursor: "pointer", marginTop: "10px" }} onClick={() => this.provider?.send("wallet_switchEthereumChain", [{ chainId: n.chainId }])}>
                        • <a>{n.name}</a>
                      </div>
                  );
                })}
              </div>
            );
            throw new Error("``chainId`` not found in ``constants.NETWORKS`` when attempting to componentDidMount()! Unsupported blockchain?");
          }
          flushSync(() => {
            this.setState({ network: NETWORKS[chainId as keyof typeof NETWORKS] });
            this.setState({ dialogMessage: defaultDialogMessage }); // close the popup
          });

          await this.onAccountConnected();
          this.getWalletBalances();
          this.subscribeProvider();
        }
        catch (error) {
          console.error(error);
          this.unsubscribeProvider();
          this.setState({ wallet: defaultWallet });
        }
      });

    this.setState({ acceptedFcaNotice: localStorage.getItem("acceptedFCANotice") === "true" });
    window.addEventListener("keydown", this.closeDialog);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.closeDialog);
  }

  setActiveOverlay = (overlay: OverlayType) => {
    this.setState({ activeOverlay: overlay });
  };

  closeDialog = (e: KeyboardEvent) => {
    if (e.key.toUpperCase() === "ESCAPE") {
      this.setState({ dialogMessage: defaultDialogMessage });
    }
  };

  onFcaNoticeAccepted = () => {
    localStorage.setItem("acceptedFCANotice", "true");
    this.setState({ acceptedFcaNotice: true });
    this.showDialog(
      <div>
        <p className="font-h3" style={{ textAlign: "center" }}><span style={{ fontSize: "0.95em" }}>Ŧ</span>rendespresso Tutorial</p>
      </div>,
      // className="SwapControl">
      //     <hr className="dotted swapPopup"/>
      //     <br />
      //     <div className="swapPopup"></div>
      <div>
        <div className="SwapControl">
          <hr className="dotted" />
          <div className="etx-spacer h-50"></div>
          <div className="etx-video Video">
            <iframe
              width="560"
              height="315"
              src={TUTORIAL_URL}
              title="Trendespresso Tutorial"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  setStrategy = (selectedStrategy: IStrategy) => {
    flushSync(() => this.setState({ selectedStrategy }));
    localStorage.setItem("selectedStrategy", JSON.stringify(selectedStrategy));

    if (this.provider) {
      this.loadContracts();
    }
  };

  setWalletConnecting(address: string) {
    this.setState({
      wallet: {
        ...this.state.wallet,
        address: address,
        balances: { TE: BigNumber(0), cash: BigNumber(0) },
        connectionState: "CONNECTING",
        provider: this.provider
      }
    });
  };

  setWalletConnectionState = (connectionState: ConnectionState) => {
    this.setState({ wallet: { ...this.state.wallet, connectionState } });
  };

  unsubscribeProvider() {
    if (!this.provider) {
      return;
    }

    this.provider.off("network");
    this.provider.off("accountsChanged");
    this.provider.off("block");
  }

  // Creates web3 object and establishes connection to provider
  connectToEth = () => {
    const w: any = window;

    // Supports modern window.ethereum web3 providers
    if (w.ethereum) {
      this.provider = new ethers.providers.Web3Provider(w.ethereum);
    }
    // Supports legacy window.web3 web3 providers
    else if (w.web3) {
      this.provider = new ethers.providers.Web3Provider(w.web3);
    }
    // No web3 provider at all
    else {
      this.showErrorMessage({ message: "Web3 provider, such as MetaMask, required to access this webpage." });
      return;
    }
  };

  subscribeProvider() {
    if (!this.provider) {
      throw new Error("No provider in subscribeProvider()!");
    }

    // Fires on network change
    this.provider.on("network", (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });

    // Fires on wallet change
    this.provider.on("accountsChanged", this.onAccountConnected);

    // Fires on new block header
    this.provider.on("block", this.doBlockchainUpdate);
  }

  subscribeToBlockchain = (callback: (...args: any[]) => Promise<void> | void) => {
    if (!this.provider) {
      throw new Error("No provider in subscribeToBlockchain()!");
    }

    this.provider.on("block", callback);
  };

  unsubscribeToBlockchain = (callback: (...args: any[]) => Promise<void> | void) => {
    if (!this.provider) {
      throw new Error("No provider in unsubscribeToBlockchain()!");
    }

    this.provider.off("block", callback);
  };

  // Load the contracts and subscribe to eth events
  async onAccountConnected() {
    flushSync(() => this.setWalletConnected());
    await this.loadContracts();
  }

  setWalletConnected = () => {
    if (!this.provider) {
      throw new Error("No provider in setWalletConnected()!");
    }

    this.setState({
      wallet: {
        ...this.state.wallet,
        connectionState: "CONNECTED",
        provider: this.provider,
        signer: this.provider.getSigner()
      }
    });
  };

  async loadContracts() {
    const { wallet } = this.state;
    const contractManager = new ContractManager(this.state.network);
    const contracts = contractManager.contracts;

    for (const contract of Object.values(contracts)) {
      if (!contract.address) {
        continue;
      }

      try {
        contract.functions = new ethers.Contract(contract.address, contract.abi, wallet.signer);
      }
      catch (error) {
        console.warn(`Error on ${contract.name}`);
        console.error(error);
      }
    }

    if (!contracts.Controller.functions) {
      throw new Error("No Controller functions in loadContracts()!");
    }

    // --- Load Frontend.sol ---
    contracts.Frontend.address = String(await contracts.Controller.functions.FRONTEND());
    if (!contracts.Frontend.address) {
      throw new Error("No Frontend address in loadContracts()!");
    }

    contracts.Frontend.functions = new ethers.Contract(contracts.Frontend.address, contracts.Frontend.abi, wallet.signer);
    console.log(`Frontend is ${contracts.Frontend.address}`);

    // --- Load TokenHolder.sol ---
    contracts.TokenHolder.address = String(await contracts.Frontend.functions.TOKEN_HOLDER());
    if (!contracts.TokenHolder.address) {
      throw new Error("No TokenHolder address in loadContracts()!");
    }

    contracts.TokenHolder.functions = new ethers.Contract(contracts.TokenHolder.address, contracts.TokenHolder.abi, wallet.signer);
    console.log(`TokenHolder is ${contracts.TokenHolder.address}`);

    // --- Load TradingSignature.sol ---
    contracts.TradingSignature.address = String(await contracts.Frontend.functions.TRADING_SIGNATURE());
    if (!contracts.TradingSignature.address) {
      throw new Error("No TradingSignature address in loadContracts()!");
    }

    contracts.TradingSignature.functions = new ethers.Contract(contracts.TradingSignature.address, contracts.TradingSignature.abi, wallet.signer);
    console.log(`TradingSignature is ${contracts.TradingSignature.address}`);

    if (!wallet.signer) {
      throw new Error("No wallet.signer in loadContracts()!");
    }

    // --- Load USDC.sol ---
    await contractManager.setupTokenContract(contracts.USDC, wallet.signer);

    // --- Load Coin.sol ---
    contractManager.contracts.Coin.name = this.state.network.nativeCurrency.name;
    contractManager.contracts.Coin.decimals = this.state.network.nativeCurrency.decimals;
    contractManager.contracts.Coin.denominator = this.state.network.nativeCurrency.denominator;

    // --- Load WCoin.sol ---
    await contractManager.setupTokenContract(contracts.WCoin, wallet.signer);

    // --- Load BTC.sol ---
    await contractManager.setupTokenContract(contracts.BTC, wallet.signer);

    // --- Load BTC_alt.sol ---
    await contractManager.setupTokenContract(contracts.BTC_alt, wallet.signer);

    // --- Load ETH.sol ---
    await contractManager.setupTokenContract(contracts.ETH, wallet.signer);

    // --- All individual contracts loaded; now generating the ``SystemSummary`` ---
    const SystemSummary = await this.getSystemSummary(contractManager);

    contracts.Account.address = SystemSummary.Account.tradingEngineInfo.addr;
    contracts.Account.functions = new ethers.Contract(contracts.Account.address, contracts.Account.abi, wallet.signer);
    console.log(`Account is ${contracts.Account.address}`);

    contracts.PooledFund.address = SystemSummary.PooledFund.tradingEngineInfo.addr;
    contracts.PooledFund.functions = new ethers.Contract(contracts.PooledFund.address, contracts.PooledFund.abi, wallet.signer);
    console.log(`PooledFund is ${contracts.PooledFund.address}`);

    contracts.DepositWithdraw.address = SystemSummary.AddressBook.depositWithdraw;
    contracts.DepositWithdraw.functions = new ethers.Contract(contracts.DepositWithdraw.address, contracts.DepositWithdraw.abi, wallet.signer);
    console.log(`DepositWithdraw is ${contracts.DepositWithdraw.address}`);

    const cashAddr = String(await contracts.DepositWithdraw.functions.cash());
    const cash = contractManager.getContractByAddress(cashAddr) as ITokenContract;

    console.log("CASH_ADDR", cashAddr);

    if (!cash) {
      throw new Error(`Unable to find contract with address ${cashAddr}`);
    }
    else if (!cash.decimals || !cash.denominator) {
      throw new Error(`Wrong type of contract returned for address ${cashAddr}`);
    }

    const assetAddr = String(await contracts.DepositWithdraw.functions.asset());
    const asset = contractManager.getContractByAddress(assetAddr) as ITokenContract;

    if (!asset) {
      throw new Error(`Unable to find contract with address ${cashAddr}`);
    }
    else if (!asset.decimals || !asset.denominator) {
      throw new Error(`Wrong type of contract returned for address ${cashAddr}`);
    }

    contractManager.cash = cash;
    contractManager.asset = asset;

    flushSync(() => this.setState({ contractManager }));
  }

  async getSystemSummary(contractManager: ContractManager = this.state.contractManager): Promise<ISystemSummaryObj> {
    if (!contractManager.contracts.Frontend.functions) {
      throw new Error("No Frontend functions in getSystemSummary()!");
    }

    try {
      const SystemSummary = fixSolidityStruct(await contractManager.contracts.Frontend.functions.getSystemSummary(this.state.selectedStrategy.num)) as ISystemSummaryObj;
      console.log("getSystemSummary()");

      if (this.state.selectedStrategy.isPooledFund) {
        SystemSummary.isRegistered = SystemSummary.PooledFund.memberNum !== BigNumber(0);
        SystemSummary.isInTrade = SystemSummary.PooledFund.tradingEngineInfo.tradeStatus.toUpperCase() !== "0X00";
        SystemSummary.currentContract = SystemSummary.PooledFund;
      }
      else {
        SystemSummary.isRegistered = SystemSummary.Account.tradingEngineInfo.addr !== ethers.constants.AddressZero;
        SystemSummary.isInTrade = SystemSummary.Account.tradingEngineInfo.tradeStatus.toUpperCase() !== "0X00";
        SystemSummary.currentContract = { ...SystemSummary.Account, pending: SystemSummary.Account.tradingEngineInfo.pending };
      }

      this.setState({ SystemSummary: SystemSummary });
      return SystemSummary;
    }
    catch (error) {
      if (isRPCError(error)) {
        if (error.message?.includes("OVERFLOW") || error.reason?.includes("OVERFLOW")) {
          console.error("Overflow error in getSystemSummary - are the ABIs up to date?");
        }
      }

      console.error(error);
      return this.state.SystemSummary;
    }
  }

  async getWalletBalances(contractManager: ContractManager = this.state.contractManager): Promise<IWalletBalances> {
    const balances: IWalletBalances = { cash: BigNumber(0), TE: BigNumber(0) };
    console.log("getWalletBalances()");

    try {
      balances.cash = await this.getTokenBalance(contractManager.contracts.USDC);
      balances.TE = await this.getTokenBalance(contractManager.contracts.TE);
    }
    catch (error) {
      console.error("Error calling balanceOf: ", error);
    }

    this.setState({ wallet: { ...this.state.wallet, balances } });
    return balances;
  }

  doBlockchainUpdate_debounce?: number;

  doBlockchainUpdate = async (header?: number) => {
    console.info("Got new header", header);

    if (this.doBlockchainUpdate_debounce && Date.now() < this.doBlockchainUpdate_debounce) {
      return;
    }

    // Only update at most once every 500ms
    const DEBOUNCE_TIME = 500;

    this.doBlockchainUpdate_debounce = Date.now() + DEBOUNCE_TIME;
    setTimeout(async () => {
      await this.getSystemSummary();
      await this.getWalletBalances();
    }, DEBOUNCE_TIME);
  };

  // ### TRADER PAGE ###
  approve = async (tokenContract: ITokenContract, addressToApprove: string, approvalAmount: ethers.BigNumberish) => {
    if (!tokenContract.functions) {
      throw new Error("No tokenContract functions in approve()!");
    }

    this.tryTx(tokenContract.functions.approve, [addressToApprove, BigNumber(approvalAmount)]);
  };

  createPermit = async (
    tokenContract: ITokenContract,
    addressToApprove: string,
    approvalAmount: ethers.BigNumberish
  ): Promise<ERC2612PermitMessage_RSV> => {
    if (!this.state.wallet.provider) {
      throw new Error("No wallet provider in createPermit()!");
    }

    if (!tokenContract.functions) {
      throw new Error("No tokenContract functions createPermit()!");
    }

    this.setLoader(true);
    const deadline = Math.floor(Date.now() / 1000) + 300;
    const nonce = Number(await tokenContract.functions.nonces(this.state.wallet.address));
    const domain = {
      name: await tokenContract.functions.name(),
      version: await tokenContract.functions.version(),
      chainId: (await this.state.wallet.provider.getNetwork()).chainId,
      verifyingContract: tokenContract.address
    };

    try {
      // Assemble and return the Permit.
      const permit = await signERC2612Permit(
        this.state.wallet.provider,
        domain,
        this.state.wallet.address,
        addressToApprove,
        BigNumber(approvalAmount).toString(),
        deadline,
        nonce
      );
      this.setLoader(false);
      return permit;
    }
    catch (error) {
      this.setLoader(false);
      throw error;
    }
  };

  tryTx = async (method: ethers.ContractFunction, params: any[] = [], value: string = "0") => {
    console.log(`tryTx --> ${method.name}(${params.join(", ")})`);
    console.log("method");
    console.log(method);
    console.log(method.name);

    try {
      this.setLoader(true);
      await method(...params, { value });
    }
    catch (error) {
      console.error(error);
      this.showErrorMessage(error);
    }

    this.setLoader(false);
  };

  parseErrorMessage = (errorReason: string) => {
    if (errorReason === "Error: VM Exception while processing transaction: reverted with reason string 'ECRecover: invalid signature 'v' value'") {
      errorReason = "DEPOSIT: Permit not signed. Transaction not attempted.";
    }
    else if (errorReason.includes("insufficient funds for gas * price + value")) {
      // This will always contain the provided regex
      // @ts-ignore
      const AVAXHaveAndReq = errorReason.match(/have [0-9]+ want [0-9]+/g)[0].split(" ");
      const AVAXHave = ethers.utils.formatEther(AVAXHaveAndReq[1]);
      const AVAXReq = ethers.utils.formatEther(AVAXHaveAndReq[3]);
      const CoinName = this.state.contractManager.contracts.Coin.name;
      errorReason = `JOIN STRATEGY: Insufficient ${CoinName} to join strategy. Have: ${AVAXHave} ${CoinName}. Required: ${AVAXReq} ${CoinName}.`;
    }
    else if (errorReason.toLowerCase().includes("user rejected transaction")) {
      errorReason = "Transaction cancelled. User rejected.";
    }
    else if (errorReason.toLowerCase().includes("user rejected the request")) {
      errorReason = "Message signature cancelled. User rejected signing.";
    }
    // Otherwise, append an "email us" message to the error.
    else {
      if (errorReason === "NetworkError when attempting to fetch resource.") {
        errorReason += " Try again in a few seconds.";
      }
      else {
        const minimumBalanceError = "Error: VM Exception while processing transaction: reverted with reason string 'MINIMUM BALANCE ERROR: Your total Wallet balance (";
        errorReason = errorReason.replace(minimumBalanceError, "Cannot join strategy. MINIMUM BALANCE ERROR: Your total Wallet balance (");
      }
      if (
        !errorReason.toLowerCase().includes("expired subscription error")
        && !errorReason.toLowerCase().includes("minimum balance error")
        && !errorReason.toLowerCase().includes("maximum balance error")
      ) {
        errorReason += " If you repeatedly receive this message, write to us: contact@trendespresso.com";
      }
    }

    return errorReason;
  };

  showErrorMessage = (error: IRPCError | unknown) => {
    let message: string;

    console.log("error");
    console.log(error);
    console.log((error as any).message);
    console.log((error as any).reason);

    if (typeof error === "string") {
      message = error;
    }
    else if (typeof error === "number") {
      message = error.toString();
    }
    else if (isRPCError(error)) {
      message = error.reason ?? error.message ?? "Unknown error object returned";
    }
    else {
      message = "No error message returned";
    }

    this.showDialog("Error", this.parseErrorMessage(message));
  };

  showDialog = (
    title: string | JSX.Element,
    content: string | JSX.Element,
    actions: IDialogAction[] = []
  ) => {
    const titleIsString = typeof title === "string";
    const contentIsString = typeof content === "string";
    actions = actions.map(a => {
      const oldAction = a.action;
      a.action = () => {
        oldAction();
        this.setState({ dialogMessage: defaultDialogMessage });
      };
      return a;
    });
    this.setState({
      dialogMessage: {
        isOpen: true,
        titleText: titleIsString ? title : defaultDialogMessage.titleText,
        titleHtml: titleIsString ? defaultDialogMessage.titleHtml : title,
        contentText: contentIsString ? content : defaultDialogMessage.contentText,
        contentHtml: contentIsString ? defaultDialogMessage.contentHtml : content,
        actions: actions.length ? actions : [{
          text: "OK",
          type: "CONFIRM",
          action: () => this.setState({ dialogMessage: defaultDialogMessage })
        }]
      }
    });
  };

  toggleDepositWithdrawDialog = (tab: DepositWithdrawDialogTab) => {
    this.setState({ dialogTab: tab, dialogState: this.state.dialogState === "OPEN" ? "CLOSED" : "OPEN" });
  };

  switchDepositWithdrawDialogTab = (tab: DepositWithdrawDialogTab) => {
    this.setState({ dialogTab: tab });
  };

  setLoader = (isLoading: boolean) => {
    this.setState({ isLoading });
  };

  // ### SWAP PAGE ###
  getTokenApprovedAmount = async (token: ITokenContract, approvedAddress: string = this.state.network.ZeroXExchangeProxy): Promise<ethers.BigNumber> => {
    const { contractManager, wallet } = this.state;

    if (!wallet) {
      console.warn("Skipping getTokenApprovedAmount()...");
      return BigNumber(-1);
    }

    if (token.address.toUpperCase() === contractManager.contracts.Coin.address.toUpperCase()) {
      return ethers.constants.MaxUint256;
    }

    try {
      if (!token.functions) {
        throw new Error("No token functions in getTokenApprovedAmount()!");
      }

      return await token.functions.allowance(wallet.address, approvedAddress);
    }
    catch (error) {
      console.error(error);
    }

    return BigNumber(-1);
  };

  getTokenBalance = async (token: ITokenContract): Promise<ethers.BigNumber> => {
    const { contractManager, wallet } = this.state;

    try {
      if (token.address.toUpperCase() === contractManager.contracts.Coin.address.toUpperCase()) {
        if (!this.provider) {
          throw new Error("No provider in getTokenBalance()!");
        }

        return await this.provider.getBalance(wallet.address);
      }
      else {
        if (!token.functions) {
          throw new Error("No token functions in getTokenBalance()!");
        }

        return await token.functions.balanceOf(wallet.address);
      }
    }
    catch (error) {
      console.error(error);
    }

    return BigNumber(-1);
  };

  approveSwap = async (token: ITokenContract) => {
    const { contractManager } = this.state;

    try {
      if (token.address.toUpperCase() === contractManager.contracts.Coin.address.toUpperCase()) {
        console.error(`Can't approve ${token.name}`);
        return;
      }

      if (!token.functions) {
        throw new Error("No token functions in approveSwap()!");
      }

      this.tryTx(token.functions.approve, [this.state.network.ZeroXExchangeProxy, ethers.constants.MaxUint256]);
    }
    catch (error) {
      console.error(error);
    }
  };

  swapTokenHolder = async (swapData: ISwapData, fromToken: IContract): Promise<void> => {
    const { contractManager } = this.state;

    console.log(swapData);
    console.log(fromToken);

    if (Number(swapData.sellAmount) <= 0) {
      return;
    }

    let value = "0x00";
    if (fromToken.address === contractManager.contracts.Coin.address) {
      value = BigInt(swapData.sellAmount).toString(16);
      // in case we have a number with only one digit
      value = value.length < 2 ? "0x0" + value : "0x" + value;
    }

    try {
      if (!contractManager.contracts.TokenHolder.functions) {
        throw new Error("No TokenHolder functions in swapTokenHolder()!");
      }

      if (fromToken.address === contractManager.contracts.Coin.address) {
        this.tryTx(contractManager.contracts.TokenHolder.functions.buyTEWithAVAX, [], value);
      }
      else if (fromToken.address === contractManager.contracts.USDC.address) {
        if (contractManager.contracts.TokenHolder.address === undefined) {
          throw new Error("TokenHolder is undefined in the ``contractManager``");
        }
        // Generate permit
        const permit = await this.createPermit(contractManager.contracts.USDC, contractManager.contracts.TokenHolder.address, BigNumber(swapData.sellAmount));
        this.tryTx(contractManager.contracts.TokenHolder.functions.buyTEWithPermitUSDC, [BigNumber(swapData.sellAmount), permit.deadline, permit.v, permit.r, permit.s]);
      }
      else {
        // (address fromToken, uint fromAmount, uint expectedTEAmount)
        this.tryTx(contractManager.contracts.TokenHolder.functions.buyTE, [fromToken.address, BigNumber(swapData.sellAmount), BigNumber(swapData.buyAmount)]);
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  swap = async (swapData: ISwapData, fromToken: IContract): Promise<void> => {
    const { contractManager, wallet } = this.state;

    if (Number(swapData.sellAmount) <= 0) {
      return;
    }

    let value = "0x00";

    if (fromToken.address === contractManager.contracts.Coin.address) {
      value = BigInt(swapData.value).toString(16);
      // in case we have a number <10
      value = value.length < 2 ? "0x0" + value : "0x" + value;
    }

    const tx = {
      to: swapData.to,
      from: wallet.address,
      data: swapData.data,
      value: value
    };

    if (!wallet.signer) {
      throw new Error("No wallet signer in swap()!");
    }

    try {
      this.setLoader(true);
      await wallet.signer.sendTransaction(tx);
    }
    catch (error) {
      console.error(error);
      this.showErrorMessage(error);
    }

    this.setLoader(false);
  };
}

export default App;
