import React from "react";
import { Input } from "src/pages/trade/sidebar/Input";
import { ContractsManagerContext, NetworkContext, StrategyContext, WalletContext } from "src/global/contexts";
import { IPriceData } from "src/pages/swap/content/Control";
import { ITradingEngineInfoObj } from "src/global/types";
import { useDebouncedCallback } from "use-debounce";
import { BigNumber, getPriceFromCoinbase, logicallyLimitDecimals } from "src/global/utils";
import { ethers } from "ethers";
import { TE_CONSTANTS } from "src/global/constants";
import { Info } from "src/pages/strategy/sidebar/Info";
import { SingleTicker } from "react-ts-tradingview-widgets";

export const defaultSliderValue = "50";

export type IPrices = {
  zeroX: {
    longEntry: number;
    longExit: number;
    shortEntry: number;
    shortExit: number;
  };
  coinbase: number;
  aave: number;
};

export const defaultPrices: IPrices = {
  zeroX: {
    longEntry: 0,
    longExit: 0,
    shortEntry: 0,
    shortExit: 0
  },
  coinbase: 0,
  aave: 0
};

export type IBalances = {
  totalCashValue: number;
  cashAvailable: number;
  assetLong: number;
  assetDebt: number;
};

export const defaultBalances: IBalances = {
  totalCashValue: 0,
  cashAvailable: 0,
  assetLong: 0,
  assetDebt: 0
};

export function Sidebar() {
  const { wallet } = React.useContext(WalletContext);
  const { network } = React.useContext(NetworkContext);
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { selectedStrategy } = React.useContext(StrategyContext);

  const [tradeTypeSelected, setTradeTypeSelected] = React.useState<"long" | "short">("long");
  const [sliderValue, setSliderValue] = React.useState(defaultSliderValue);

  const [fetchingAAVEPrice, setFetchingAAVEPrice] = React.useState(false);
  const [fetchingZeroXPrice, setFetchingZeroXPrice] = React.useState(false);

  const [balances, setBalances] = React.useState({ ...defaultBalances });
  const [prices, setPrices] = React.useState({ ...defaultPrices });

  const [getBalances_timeout, setGetBalances_timeout] = React.useState<NodeJS.Timer>();
  const [getPrices_timeout, setGetPrices_timeout] = React.useState<NodeJS.Timer>();

  const tradingViewTicker: string | undefined =
    contractManager.asset.coinbaseName && contractManager.cash.coinbaseName
      ? contractManager.asset.coinbaseName + contractManager.cash.coinbaseName
      : undefined;

  // VISIBILITY
  React.useEffect(() => {
    function onVisibilityChange() {
      if (document.hidden) {
        clearTimeouts();
      }
      else {
        setTimeouts();
      }
    }

    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []);

  // TIMEOUTS
  React.useEffect(setTimeouts, [selectedStrategy, tradeTypeSelected, sliderValue]);
  React.useEffect(() => {
    getPrices();
  }, [
    contractManager.contracts.DepositWithdraw.functions,
    contractManager.cash.coinbaseName,
    contractManager.asset.coinbaseName
  ]);

  React.useEffect(() => {
    getBalances_patient500();
  }, [
    contractManager.contracts.Frontend.functions,
    contractManager.contracts.Controller.functions,
    contractManager.contracts.PooledFund.functions,
    contractManager.contracts.PooledFund.address,
    contractManager.asset.address,
    contractManager.cash.address
  ]);

  function setTimeouts() {
    getBalances_patient500();
    getPrices_patient500();
  }

  const getBalances_patient500 = useDebouncedCallback(() => {
    console.log("get balances");
    getBalances();

    clearTimeout(getBalances_timeout);
    setGetBalances_timeout(setTimeout(getBalances_patient500, 30_000)); // every 30 seconds
  }, 500);

  const getPrices_patient500 = useDebouncedCallback(() => {
    console.log("get prices");
    getPrices();

    clearTimeout(getPrices_timeout);
    setGetPrices_timeout(setTimeout(getPrices_patient500, 5_000)); // every 5 seconds
  }, 500);

  function clearTimeouts() {
    clearTimeout(getBalances_timeout);
    clearTimeout(getPrices_timeout);
  }

  // PRICES
  const getPrices = async () => {
    if (
      !contractManager.contracts.DepositWithdraw.functions
      || !contractManager.cash.coinbaseName
      || !contractManager.asset.coinbaseName
    ) {
      return;
    }

    const cashAvailable = balances.cashAvailable > 0 ? balances.cashAvailable : contractManager.cash.denominator.toNumber() * 100;
    const assetLong = balances.assetLong > 0 ? balances.assetLong : contractManager.asset.denominator.toNumber();
    const assetDebt = balances.assetDebt > 0 ? balances.assetDebt : contractManager.asset.denominator.toNumber();

    const pricesTmp = { ...prices };
    if (tradeTypeSelected === "long") {
      const [zeroXQuote_longEntry, zeroXQuote_longExit] = await Promise.all([
        getZeroXv1Price_sell(contractManager.cash.address, contractManager.asset.address, Math.floor(cashAvailable * Number(sliderValue) / 100)),
        getZeroXv1Price_sell(contractManager.asset.address, contractManager.cash.address, Math.floor(assetLong * Number(sliderValue) / 100))
      ]);

      if (!zeroXQuote_longEntry) {
        console.warn("Failed to get zeroXQuote_longEntry in Trade Page Sidebar");
        console.log("zeroXQuote_longEntry", zeroXQuote_longEntry);
        console.log("zeroXQuote_longExit", zeroXQuote_longExit);
        return;
      }

      if (!zeroXQuote_longExit) {
        console.warn("Failed to get zeroXQuote_longExit in Trade Page Sidebar");
        console.log("zeroXQuote_longEntry", zeroXQuote_longEntry);
        console.log("zeroXQuote_longExit", zeroXQuote_longExit);
        return;
      }

      pricesTmp.zeroX.longEntry = 1 / Number(zeroXQuote_longEntry.price);
      pricesTmp.zeroX.longExit = Number(zeroXQuote_longExit.price);
      pricesTmp.zeroX.shortEntry = 0;
      pricesTmp.zeroX.shortExit = 0;
    }
    else if (tradeTypeSelected === "short") {
      const [zeroXQuote_shortEntry, zeroXQuote_shortExit] = await Promise.all([
        getZeroXv1Price_sell(contractManager.cash.address, contractManager.asset.address, Math.floor(cashAvailable * Number(sliderValue) / 100)),
        getZeroXv1Price_buy(contractManager.cash.address, contractManager.asset.address, Math.floor(assetDebt * Number(sliderValue) / 100))
      ]);

      if (!zeroXQuote_shortEntry) {
        console.warn("Failed to get zeroXQuote_shortEntry in Trade Page Sidebar");
        return;
      }

      if (!zeroXQuote_shortExit) {
        console.warn("Failed to get zeroXQuote_shortExit in Trade Page Sidebar");
        return;
      }

      pricesTmp.zeroX.longEntry = 0;
      pricesTmp.zeroX.longExit = 0;
      pricesTmp.zeroX.shortEntry = 1 / Number(zeroXQuote_shortEntry.price);
      pricesTmp.zeroX.shortExit = Number(zeroXQuote_shortExit.price);
    }
    else {
      pricesTmp.zeroX.longEntry = 0;
      pricesTmp.zeroX.longExit = 0;
      pricesTmp.zeroX.shortEntry = 0;
      pricesTmp.zeroX.shortExit = 0;
    }

    const blockchainPriceReceived: Promise<ethers.BigNumber> = contractManager.contracts.DepositWithdraw.functions.getPriceOf(contractManager.asset.address);
    pricesTmp.aave = (await blockchainPriceReceived).toNumber();
    pricesTmp.coinbase = await getPriceFromCoinbase(contractManager.asset.coinbaseName, contractManager.cash.coinbaseName);

    spinPriceWheels();
    setPrices(pricesTmp);
  };

  const spinPriceWheels = () => {
    setFetchingAAVEPrice(true);
    setFetchingZeroXPrice(true);
    setTimeout(() => setFetchingAAVEPrice(false), 350);
    setTimeout(() => setFetchingZeroXPrice(false), 350);
  };

  // BALANCES
  React.useEffect(() => {
    getBalances();
  }, [selectedStrategy]);

  const getBalances = async () => {
    if (
      !contractManager.contracts.Frontend.functions
      || !contractManager.contracts.Controller.functions
      || !contractManager.contracts.PooledFund.functions
      || !contractManager.contracts.PooledFund.address
      || !contractManager.asset.address
      || !contractManager.cash.address
    ) {
      return;
    }

    // Grab the TradingEngineInfo of all Accounts within the ``selectedStrategy``.
    const tradingEngineInfoAccounts: ITradingEngineInfoObj[] =
      await contractManager.contracts.Frontend.functions.getTradingEngineInfoList(
        selectedStrategy.num,
        0,
        await contractManager.contracts.Controller.functions.getAccountsCountByStrategyNum(selectedStrategy.num)
      );

    // Grab the TradingEngineInfo of the PooledFund.
    // const PooledFund = await contractManager.contracts.Controller.functions.getPooledFundOf(selectedStrategy.id);
    const tradingEngineInfos: ITradingEngineInfoObj[] = [
      ...tradingEngineInfoAccounts,
      await contractManager.contracts.Frontend.functions.getTradingEngineInfoOf(contractManager.contracts.PooledFund.address)
    ];

    const balancesTmp = { ...balances };

    // Sum together all the Accounts and the PooledFund.
    let _totalCashValueBefore = BigNumber(0);
    tradingEngineInfos.map(e => e.totalCashValue).forEach(t => _totalCashValueBefore = _totalCashValueBefore.add(t));
    balancesTmp.totalCashValue = _totalCashValueBefore.toNumber();

    let _cashAvailableToTrade = BigNumber(0);
    tradingEngineInfos.map(e => e.cashAvailableToTrade).forEach(t => _cashAvailableToTrade = _cashAvailableToTrade.add(t));
    balancesTmp.cashAvailable = _cashAvailableToTrade.toNumber();

    let _assetDebt = BigNumber(0);
    tradingEngineInfos.map(e => e.assetShort).forEach(t => _assetDebt = _assetDebt.add(t));
    balancesTmp.assetDebt = _assetDebt.toNumber();

    let _assetLong = BigNumber(0);
    tradingEngineInfos.map(e => e.assetLong).forEach(t => _assetLong = _assetLong.add(t));
    tradingEngineInfos.map(e => e.assetAvailable).forEach(t => _assetLong = _assetLong.add(t));
    balancesTmp.assetLong = _assetLong.toNumber();

    setBalances(balancesTmp);
  };

  const getZeroXv1Price_buy = async (
    sellToken: string,
    buyToken: string,
    buyAmount: ethers.BigNumberish
  ) => {
    console.log("entered the sell 0x price | buyAmount", buyAmount);

    if (BigNumber(buyAmount).lte(0)) {
      console.warn(`Ignoring invalid 0x buy amount: ${buyAmount}`);
      return;
    }

    const URL = `${network.base0xURL_v1}`
      + `/swap/v1/price`
      + `?buyToken=${buyToken}`
      + `&sellToken=${sellToken}`
      + `&buyAmount=${String(buyAmount)}`
      + `&slippagePercentage=${TE_CONSTANTS.TRADER_SLIPPAGE}`;

    return await _getZeroXv1Price(URL);
  };

  const getZeroXv1Price_sell = async (
    sellToken: string,
    buyToken: string,
    sellAmount: ethers.BigNumberish
  ) => {
    console.log("entered the sell 0x price | sellAmount", sellAmount);

    if (BigNumber(sellAmount).lte(0)) {
      console.warn(`Ignoring invalid 0x sell amount: ${sellAmount}`);
      return;
    }

    const URL = `${network.base0xURL_v1}`
      + `/swap/v1/price`
      + `?buyToken=${buyToken}`
      + `&sellToken=${sellToken}`
      + `&sellAmount=${String(sellAmount)}`
      + `&slippagePercentage=${TE_CONSTANTS.TRADER_SLIPPAGE}`;

    return await _getZeroXv1Price(URL);
  };

  const _getZeroXv1Price = async (URL: string): Promise<IPriceData> => {
    console.log("URL:", URL);

    const response = await fetch(URL, { headers: TE_CONSTANTS.ZeroXAPIKeyHeader_v1 });
    const json = await response.json();

    if (response.status !== 200) {
      throw new Error(JSON.stringify({ URL, json }, null, 2));
    }

    return json;
  };

  if (wallet.connectionState !== "CONNECTED") {
    return (
      <aside className="etx-body__aside">
        <div className="etx-container Container pv-0"></div>
      </aside>
    );
  }

  return (
    <aside className="etx-body__aside">

      <div className="etx-container Container pv-0">
        <Input
          tradeTypeSelected={tradeTypeSelected}
          setTradeTypeSelected={setTradeTypeSelected}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          prices={prices}
          balances={balances}
        />
      </div>

      <div style={{ height: "55px", fontSize: "0px" }}>
        TRADING PAGE DIV SPACER
      </div>

      <div className="etx-container Container pv-0">
        <div className="etx-card Card bg-white-1-20 overflow-hidden tradingViewWidget">
          <div className="etx-card__item Card__item ph-0 pv-0">
            <SingleTicker colorTheme="dark" width="100%" autosize={true} symbol={tradingViewTicker ?? "BTCUSD"}></SingleTicker>
          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <hr />
          </div>

          <div className="etx-card__item Card__item pv-50 strategyInfo_hover">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
              <div className="etx-flex__item Flex__item shrink-0">
                <div className="etx-text Text">
                  <p className="font-size-large font-weight-medium">
                    {logicallyLimitDecimals(prices.aave / TE_CONSTANTS.AAVE_DENOMINATOR.toNumber())}
                  </p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="etx-text Text a-right">
                  <div className="font-size-medium font-weight-medium">
                    <div className="etx-text Text a-right">
                      <div className="font-label">{contractManager.asset.name}<br />(AAVE Price)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-flex__item Flex__item shrink-0">
                <i className={`fal fa-rotate-right cycleArrows350 ${fetchingAAVEPrice ? "fa-spin" : ""}`}></i>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <hr />
          </div>

          <div className="etx-card__item Card__item pv-50 strategyInfo_hover">
            <div className="etx-flex Flex c-auto av-center gh-50 gv-0">
              <div className="etx-flex__item Flex__item shrink-0">
                <div className="etx-text Text">
                  <p className="font-size-large font-weight-medium">
                    {logicallyLimitDecimals(prices.zeroX.longEntry)}
                  </p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="etx-text Text a-right">
                  <div className="font-size-medium font-weight-medium">
                    <div className="etx-text Text a-right">
                      <div className="font-label">{contractManager.asset.name}<br />(0x API Price)</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="etx-flex__item Flex__item shrink-0">
                <i className={`fal fa-rotate-right cycleArrows350 ${fetchingZeroXPrice ? "fa-spin" : ""}`}></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "35px", fontSize: "0px" }}>
        TRADING PAGE DIV SPACER
      </div>

      <div className="etx-container Container pt-50 tab-m:pt-25 pb-0">
        <Info
          layout="trade"
          balances={balances}
          totalCashValue={balances.totalCashValue}
        />
      </div>

    </aside>
  );
}
