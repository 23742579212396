import { ethers } from "ethers";
import { BigNumber } from "./utils";
import { Modify } from "./helpers";
import { DEFAULT_STRATEGY_INDEX, strategies } from "src/global/strategies";

const _defaultPendingInfo = {
	depositAmountCash: BigNumber(0),
	withdrawAmountCash: BigNumber(0),
	withdrawAmountTE: BigNumber(0)
};
export type IPendingInfoObj = typeof _defaultPendingInfo;
export const defaultPendingInfo: IPendingInfoObj = _defaultPendingInfo;

const _defaultTradingEngineInfo = {
	addr: ethers.constants.AddressZero,
	tradeStatus: "0x00",
	isPooled: false,
	owesSubscriptionFee: true,
	strategyNum: BigNumber(1),
	assetLong: BigNumber(0),
	assetShort: BigNumber(0),
	assetAvailable: BigNumber(0),
	cashAvailableToTrade: BigNumber(0),
	totalCashValue: BigNumber(0),
	pending: defaultPendingInfo
};
export type ITradingEngineInfoObj = typeof _defaultTradingEngineInfo;
export const defaultTradingEngineInfo: ITradingEngineInfoObj = _defaultTradingEngineInfo;

const _defaultHistoricalBalances = {
	profitSharePaid: 0,
	pnl: 0,
	deposited: 0,
	swapsLength: 0,
	positionsLength: 0,
	current: BigNumber(0),
	pretrade: BigNumber(0)
};
export type IHistoricalBalancesObj = typeof _defaultHistoricalBalances;
export const defaultHistoricalBalances: IHistoricalBalancesObj = _defaultHistoricalBalances;

const _defaultHistoricalPositions = {
	timestamp: Math.floor(Date.now() / 1000),
	pendingWithdrawals: "0x00",
	healthFactor: 0,
	assetExtra: 0,
	aaveCollateralDebt: "0x00",
	assetUnsoldLong: BigNumber(0),
	assetUnsoldShort: BigNumber(0),
	swapsLength: 0
};
export type IHistoricalPositionsObj = typeof _defaultHistoricalPositions;
export const defaultHistoricalPositions: IHistoricalPositionsObj = _defaultHistoricalPositions;

const _defaultPctPositions = {
	pctCash: BigNumber(10).pow(18),
	pctLong: BigNumber(0),
	pctShort: BigNumber(0),
	pctReserve: BigNumber(0),
	totalCashValue: BigNumber(0)
};
export type IPctPositionsObj = typeof _defaultPctPositions;
export const defaultPctPositions: IPctPositionsObj = _defaultPctPositions;

const _defaultHistoricalTrade = {
	profitShare: BigNumber(0),
	pnl: BigNumber(0)
};
export type IHistoricalTradeObj = typeof _defaultHistoricalTrade;
export const defaultHistoricalTrade: IHistoricalTradeObj = _defaultHistoricalTrade;

const _defaultSubscriptionInfo = {
	feePerMonth: BigNumber(200).mul(BigNumber(10).pow(6)),
	paidThru: BigNumber(0),
	outstandingPaymentInTE: BigNumber(0) // Should this be feePerMonth?
};
export type ISubscriptionInfoObj = typeof _defaultSubscriptionInfo;
export const defaultSubscriptionInfo: ISubscriptionInfoObj = _defaultSubscriptionInfo;

const _defaultRawValues = {
	tradeCount: BigNumber(0),
	ltv: BigNumber(0),
	totalCollateralBase: BigNumber(0),
	totalDebtBase: BigNumber(0),
	cashBalanceOf: BigNumber(0),
	assetBalanceOf: BigNumber(0),
	assetPrice: BigNumber(0),
	totalCashValue: BigNumber(0)
};
export type IRawValuesObj = typeof _defaultRawValues;
export const defaultRawValues: IRawValuesObj = _defaultRawValues;

const _defaultSysAccountInfo = {
	tradingEngineInfo: defaultTradingEngineInfo,
	contractHistorical: defaultHistoricalTrade,
	contractBalance: defaultHistoricalBalances,
	contractPosition: defaultHistoricalPositions,
	pctPositions: defaultPctPositions,
	subscription: defaultSubscriptionInfo,
	rawValues: defaultRawValues,
	minimumBalanceCashDeposit: BigNumber(0),
	minimumBalanceCashWithdraw: BigNumber(0),
	maximumBalanceCash: BigNumber(0)
};
export type ISysAccountInfoObj = typeof _defaultSysAccountInfo;
export const defaultSysAccountInfo: ISysAccountInfoObj = _defaultSysAccountInfo;

const _defaultHistoricalMember = {
	profitSharePaid: 0,
	pnl: 0,
	deposited: 0,
	pendingTransfers: "0x00",
	current: 0,
	pretrade: 0,
	subscriptionPaidThru: 0
};
export type IHistoricalMemberObj = typeof _defaultHistoricalMember;
export const defaultHistoricalMember: IHistoricalMemberObj = _defaultHistoricalMember;

const _defaultSysPooledFundInfo = {
	tradingEngineInfo: { ...defaultTradingEngineInfo, isPooled: true },
	contractHistorical: defaultHistoricalTrade,
	contractBalance: defaultHistoricalBalances,
	contractPosition: defaultHistoricalPositions,
	pctPositions: defaultPctPositions,
	subscription: { ...defaultSubscriptionInfo, feePerMonth: defaultSubscriptionInfo.feePerMonth.div(2) },
	rawValues: defaultRawValues,
	minimumBalanceCashDeposit: BigNumber(0),
	minimumBalanceCashWithdraw: BigNumber(0),
	maximumBalanceCash: BigNumber(0),
	memberCount: BigNumber(0),
	memberNum: BigNumber(0),
	memberBalance: defaultHistoricalMember,
	memberHistorical: defaultHistoricalTrade,
	pending: defaultPendingInfo,
	pctOwned: BigNumber(0)
};
export type ISysPooledFundInfoObj = typeof _defaultSysPooledFundInfo;
export const defaultSysPooledFundInfo: ISysPooledFundInfoObj = _defaultSysPooledFundInfo;

const _defaultStrategyInfo = {
	strategyInfo1: "",
	strategyInfo2: "",
	cash: ethers.constants.AddressZero,
	cashDecimals: BigNumber(6),
	cashDenominator: BigNumber(10).pow(6),
	cashSymbol: "USDC",
	asset: ethers.constants.AddressZero,
	assetDecimals: BigNumber(8),
	assetDenominator: BigNumber(10).pow(8),
	assetSymbol: "BTC"
};
export type IStrategyInfoObj = typeof _defaultStrategyInfo;
export const defaultStrategyInfo: IStrategyInfoObj = _defaultStrategyInfo;

const _defaultStrategyAddressBook = {
	accountFactory: ethers.constants.AddressZero,
	depositWithdraw: ethers.constants.AddressZero,
	treasury: ethers.constants.AddressZero,
	tradingWallet: ethers.constants.AddressZero,
	pooledFund: ethers.constants.AddressZero
};
export type IStrategyAddressBookObj = typeof _defaultStrategyAddressBook;
export const defaultStrategyAddressBook: IStrategyAddressBookObj = _defaultStrategyAddressBook;

const _defaultOwnerInfo = {
	balanceTE: 0,
	pctProfitShare: BigNumber(20).mul(BigNumber(10).pow(18)).div(100)
};
export type IOwnerInfoObj = typeof _defaultOwnerInfo;
export const defaultOwnerInfo: IOwnerInfoObj = _defaultOwnerInfo;

const _defaultSettings = {
	pctAutoDepositIntoAAVE: BigNumber(100_000_000_000_000_000n),				// where 200 is 100%
	thousandsUSDAutoDepositIntoAAVE: BigNumber(50_000_000_000n),				// where 1 is $1,000
	thousandsUSDForcePriceProtection: BigNumber(4_000_000_000n),				// where 1 is $1,000
	pctSlippageWithdrawNowPctPositions: BigNumber(10_000_000_000_000_000n),		// where 200 is 10%
	pctSlippageWithdrawNowCashValueChange: BigNumber(8_000_000_000_000_000n),	// where 200 is 10%
	pctSlippageGeneral: BigNumber(9_000_000_000_000_000n),						// where 200 is 10%
	pctMaxToBorrowDiv2: BigNumber(700_000_000_000_000_000n),					// where 200 is 100% and 156 is 78%
	minHealthFactor: BigNumber(1_200_000_000_000_000_000n),						// where 100 is 1.00
	expiredSubscriptionWithdrawAccounts: BigNumber(1n),							// simple Y/n boolean where 0x00 is `false` and any other value is `true`
	expiredSubscriptionWithdrawPooledMembers: BigNumber(1n),					// simple Y/n boolean where 0x00 is `false` and any other value is `true`
	pctMinProfitShare: BigNumber(10_000_000_000_000_000n),						// 65535 is 65.535% | concat with below
	pctProfitShare: BigNumber(200_000_000_000_000_000n),						// 65535 is 65.535% | concat with below
	pctOneReductionPerTE: BigNumber(10_000_000_000n),							// ❎ where Ŧ1 == literal 1 --> Ŧ234 would be converted inline to 234_000_000
	perMonthChargeInTE: BigNumber(200_000_000n),
	fromStorage: "0x1432041410128c78010103e84e200002540be40000000bebc200"
};
export type ISettingsObj = typeof _defaultSettings;
export const defaultSettings: ISettingsObj = _defaultSettings;

const _defaultSysControllerInfo = {
	ownerInfoCurrent: defaultOwnerInfo,
	ownerInfoAfterSubscriptionPayments: defaultOwnerInfo,
	settings: defaultSettings
};
export type ISysControllerInfoObj = typeof _defaultSysControllerInfo;
export const defaultSysControllerInfo: ISysControllerInfoObj = _defaultSysControllerInfo;

const defaultCurrentContract = strategies[DEFAULT_STRATEGY_INDEX].isPooledFund ? defaultSysPooledFundInfo : defaultSysAccountInfo;
const _defaultSystemSummary = {
	owner: ethers.constants.AddressZero,
	Account: defaultSysAccountInfo,
	PooledFund: defaultSysPooledFundInfo,
	Strategy: defaultStrategyInfo,
	AddressBook: defaultStrategyAddressBook,
	Controller: defaultSysControllerInfo,
	currentContract: { ...defaultCurrentContract, pending: defaultPendingInfo },
	isRegistered: false,
	isInTrade: false
};
export type ISystemSummaryObj = typeof _defaultSystemSummary;
export const defaultSystemSummary: ISystemSummaryObj = _defaultSystemSummary;

export type ITradeInfoObj = {
	Account: string;
	order: number;
	feesOperation: number;
	shortAmounts: [ethers.BigNumber, ethers.BigNumber];
	priceProtection: [ethers.BigNumber, ethers.BigNumber, ethers.BigNumber];
	swapCallData: string;
};

// Users' wallet
const _defaultWallet = {
	address: ethers.constants.AddressZero,
	balances: { TE: BigNumber(0), cash: BigNumber(0) },
	connectionState: "INIT",
	provider: undefined,
	signer: undefined
};
export type IWallet = Modify<typeof _defaultWallet, { provider?: ethers.providers.Web3Provider, signer?: ethers.providers.JsonRpcSigner; }>;
export const defaultWallet: IWallet = _defaultWallet;

export type IWalletBalances = {
	TE: ethers.BigNumber;
	cash: ethers.BigNumber;
};

export type ConnectionState = "INIT" | "CONNECTING" | "CONNECTED";
export type DialogState = "OPEN" | "CLOSED";
export type DepositWithdrawDialogTab = "DEPOSIT" | "WITHDRAW" | "WITHDRAW_NOW" | "NONE";

const _defaultPermit = {
	owner: ethers.constants.AddressZero,
	spender: ethers.constants.AddressZero,
	value: 0,
	nonce: 0,
	deadline: 0,
	r: "0x" + "0".repeat(64),
	s: "0x" + "0".repeat(64),
	v: 0
};
export type ERC2612PermitMessage_RSV = Modify<typeof _defaultPermit, { value: string | number; nonce: number | string; deadline: number | string; }>;
export const defaultPermit: ERC2612PermitMessage_RSV = _defaultPermit;

const _rpcError = {
	reason: undefined,
	message: undefined
};
export type IRPCError = Modify<typeof _rpcError, { reason?: string; message?: string; }>;
export function isRPCError(obj: unknown): obj is IRPCError {
	if (obj && typeof obj === "object") {
		if ("reason" in obj && typeof obj.reason === "string") {
			return true;
		}
		else if ("message" in obj && typeof obj.message === "string") {
			return true;
		}
	}
	return false;
}

const _defaultTradingEngineInfoAndAccountInfo = {
	tradingEngineInfo: defaultTradingEngineInfo,
	accountInfoHistorical: defaultSysAccountInfo
};
export type ITradingEngineInfoAndAccountInfo = typeof _defaultTradingEngineInfoAndAccountInfo;
export const defaultTradingEngineInfoAndAccountInfo: ITradingEngineInfoAndAccountInfo = _defaultTradingEngineInfoAndAccountInfo;

export type IRoutes = "/" | "/fca" | "/swap" | "/memberships" |  "/trade";