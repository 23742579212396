import Controller_ABI from "./ABI_Controller.json";
import Account_ABI from "./ABI_Account.json";
import PooledFund_ABI from "./ABI_PooledFund.json";
import DepositWithdraw_ABI from "./ABI_DepositWithdraw.json";
import Frontend_ABI from "./ABI_Frontend.json";
import TokenHolder_ABI from "./ABI_TokenHolder.json";
import TradingSignature_ABI from "./ABI_TradingSignature.json";
import GaslessDeposit_ABI from "./ABI_GaslessDeposit.json";
import ERC20_ABI from "./ABI_IERC20TE.json";
import { ethers } from "ethers";
import { BigNumber } from "src/global/utils";
import { Modify } from "src/global/helpers";
import { TE_ADDR, CTRL_ADDR, INetwork } from "src/global/constants";

const _defaultContract = {
  name: "",
  abi: new Array<any>(),
  address: "",
  functions: undefined
};
export type IContract = Modify<typeof _defaultContract, { functions?: ethers.Contract; }>;
const defaultContract: IContract = _defaultContract;

const _defaultContract_NoAddress = {
  ...defaultContract,
  address: undefined
};
export type IContract_NoAddress = Modify<typeof _defaultContract_NoAddress, { address?: string; }>;
// const defaultContract_NoAddress: IContract_NoAddress = _defaultContract_NoAddress;

const _defaultTokenContract = {
  ...defaultContract,
  coinbaseName: undefined,
  decimals: BigNumber(1), // Be aware the default value of 1 is to avoid division by zero errors
  denominator: BigNumber(1) // Be aware the default value of 1 is to avoid division by zero errors
};
export type ITokenContract = Modify<typeof _defaultTokenContract, { coinbaseName?: string; }>;
const defaultTokenContract: ITokenContract = _defaultTokenContract;

// Addresses updated 2 June 2024
const contracts = {
  Controller: {
    name: "Controller",
    abi: Controller_ABI,
    address: CTRL_ADDR, // permanent // OLD: "0x7E7EE0FFd004b16DC3a5F9A1d9862575D0b033Ae", 
    functions: undefined
  } as IContract,
  Account: {
    name: "Account",
    abi: Account_ABI,
    address: undefined, // grab from Frontend --> SystemSummary()
    functions: undefined
  } as IContract_NoAddress,
  PooledFund: {
    name: "PooledFund",
    abi: PooledFund_ABI,
    address: undefined, // grab from Frontend --> SystemSummary()
    functions: undefined
  } as IContract_NoAddress,
  DepositWithdraw: {
    name: "DepositWithdraw",
    abi: DepositWithdraw_ABI,
    address: undefined, // grab from Frontend --> SystemSummary()
    functions: undefined
  } as IContract_NoAddress,
  Frontend: {
    name: "Frontend",
    abi: Frontend_ABI,
    address: undefined, // grab from Controller --> FRONTEND()
    functions: undefined
  } as IContract_NoAddress,
  // TODO Make TokenHolder modular depending on the blockchain used (ex. AVAX vs ARB)
  TokenHolder: {
    name: "TokenHolder",
    abi: TokenHolder_ABI,
    address: undefined, // OLD, broken wavax: "0x2D0c136451D0AF0Dc81fff9aE421CE7D7950db49", // permanent
    functions: undefined
  } as IContract_NoAddress,
  // TODO Make TradingSignature modular depending on the blockchain used (ex. AVAX vs ARB)
  TradingSignature: {
    name: "TradingSignature",
    abi: TradingSignature_ABI,
    address: undefined, // permanent
    functions: undefined
  } as IContract_NoAddress,
  // TODO Make GaslessDeposit modular depending on the blockchain used (ex. AVAX vs ARB)
  GaslessDeposit: {
    name: "GaslessDeposit",
    abi: GaslessDeposit_ABI,
    address: "0x322fa6fD1b4C6849F0DeEa329563c96714b65ddD", // as deployed on the Fork
    functions: undefined
  } as IContract,
  TE: {
    name: "TE",
    abi: ERC20_ABI,
    address: TE_ADDR, // permanent
    functions: undefined,
    coinbaseName: undefined,
    decimals: BigNumber(6),
    denominator: BigNumber(10).pow(6)
  } as ITokenContract,
  USDC: {
    name: "",
    abi: ERC20_ABI,
    address: "", // permanent
    functions: undefined,
    coinbaseName: "USD",
    decimals: BigNumber(6),
    denominator: BigNumber(10).pow(6)
  } as ITokenContract,
  Coin: {
    name: "",
    abi: ERC20_ABI,
    address: "", // permanent
    functions: undefined,
    coinbaseName: "AVAX",
    decimals: BigNumber(18),
    denominator: BigNumber(10).pow(18)
  } as ITokenContract,
  WCoin: {
    name: "", // === "W" + ``Coin.name``
    abi: ERC20_ABI,
    address: "", // permanent
    functions: undefined,
    coinbaseName: "AVAX",
    decimals: BigNumber(18),
    denominator: BigNumber(10).pow(18)
  } as ITokenContract,
  BTC: {
    name: "",
    abi: ERC20_ABI,
    address: "", // permanent
    functions: undefined,
    coinbaseName: "BTC",
    decimals: BigNumber(8),
    denominator: BigNumber(10).pow(8)
  } as ITokenContract,
  BTC_alt: {
    name: "",
    abi: ERC20_ABI,
    address: "", // permanent
    functions: undefined,
    coinbaseName: "BTC",
    decimals: BigNumber(8),
    denominator: BigNumber(10).pow(8)
  } as ITokenContract,
  ETH: {
    name: "",
    abi: ERC20_ABI,
    address: "", // permanent
    functions: undefined,
    coinbaseName: "ETH",
    decimals: BigNumber(18),
    denominator: BigNumber(10).pow(18)
  } as ITokenContract
};

export class ContractManager {
  contracts = contracts;

  cash = defaultTokenContract;
  asset = defaultTokenContract;

  constructor(network: INetwork) {
    this.contracts.USDC.address = network.USDC;
    this.contracts.Coin.address = network.Coin;
    this.contracts.WCoin.address = network.WCoin;
    this.contracts.BTC.address = network.BTC;
    this.contracts.BTC_alt.address = network.BTC_alt;
    this.contracts.ETH.address = network.ETH;
  }

  getContractByAddress(address: string): IContract | IContract_NoAddress | ITokenContract | undefined {
    for (const contract of Object.values(this.contracts)) {
      if (contract.address?.toUpperCase() === address.toUpperCase()) {
        return contract;
      }
    }
  }

  async setupTokenContract(token: ITokenContract, signer: ethers.Signer) {
    token.functions = new ethers.Contract(token.address, token.abi, signer);
    token.name = await token.functions.symbol();
    console.log(`${token.name} is ${token.address}`);
  }
}