import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import React from "react";
import { WalletAndFaq } from "src/pages/WalletAndFaq";
import { Navigation } from "src/pages/Navigation";
import { IRoutes } from "src/global/types";


interface IProps {
  activeRoute: IRoutes;
  content: ReactJSXElement;
  sidebar: ReactJSXElement;
}

const defaultProps: IProps = {
  activeRoute: "/",
  content: <></>,
  sidebar: <></>
};

export function PageTemplate(props = defaultProps) {
  return (
    <div className="etx-body-wrapper">
      <main className="etx-body">
        <WalletAndFaq />
        <Navigation activeRoute={props.activeRoute} />
        <section className="etx-body__main">{props.content}</section>
        {props.sidebar}
      </main>
    </div>
  );
}
