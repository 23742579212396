export interface IStrategy {
  name: string;
  num: number;
  isPooledFund: boolean;
  description: IDescription;
  // outcomeWatcher: IOutcomeWatcher;
};

// interface IOutcomeWatcher = {
//   long: boolean;
//   short: boolean;
//   pctPositions: IPctPositionsObj;
// };

// const defaultOutcomeWatcher: IOutcomeWatcher = {
//   long: false,
//   short: false,
//   pctPositions: defaultPctPositions
// };

interface IDescription {
  cash: "USDC";
  asset: "BTC.b" | "WAVAX";
  traderType: "Human" | "Automated";
  supervisor: string;
  basePctProfitShare: number;
  category: "Scalp" | "Swing" | "Macro";
  outcomeWatcherAccount: string;
  info: string[];
};

const defaultDescription: IDescription = {
  cash: "USDC",
  asset: "BTC.b",
  traderType: "Human",
  supervisor: "Trendespresso",
  basePctProfitShare: 20.00,
  category: "Swing",
  outcomeWatcherAccount: "0x0000000000000000000000000000000000000000",
  info: ["{INFO PLACEHOLDER}"],
};

// MUST be index-matched to the strategies as stored on the blockchain
export const strategyDescriptions: IDescription[] = [
  // Strategy ID 0 should be null and never accessed
  defaultDescription,
  { // Strategy ID 1
    ...defaultDescription,
    traderType: "Automated",
    outcomeWatcherAccount: "0xF2BFdE31a639e28e9e7e86Eccab56B65245b12D0",
    info: [`
      Our in-house signal generator is a game-changer and cannot be found anywhere else.
      Built with automated trading in mind, Trendespresso has carefully crafted this strategy to identify trends after initial confirmation and maintain an optimistic outlook to prevent being "wicked out" of the trade. When other automated signal generators flip back and forth aimlessly, Affogato waits for the perfect time to strike, makes its move, then patiently waits for the best exit.`,
      `This strategy falls squarely into the Trend Following category of strategies. Emphasis is put on waiting for a clear trend before making any decision.`,
    ]
  },
  { // Strategy ID 2
    ...defaultDescription,
    category: "Macro",
    outcomeWatcherAccount: "0x0e9617ab8DC1a981Dca1D20D00F6e497eC25Cb97",
    info: [`
      Preserve your capital with patient deployment and maximise your chances at profitability. Our macro strategies all follow the larger market trend and can easily last months.
      If you join a macro strategy using Pooled mode, we will attempt to cycle trades quarterly to ensure deposits and withdrawals can be processed multiple times intra-year. As a reminder, Individual mode always allows for immediate deposits and immediate withdrawals.
      Macro strategies are a much more secure way to gain exposure to cryptoassets and ride the rocket 🚀`,
      `Partial entries and partial exits each over an extended period of time to average into the money at opposite ends of the market cycle are key to this strategy. This is a Macro strategy and is heavily focused on patient time-based trading. Average into a Long position at the bottom, then average into a Short position at the top.`
    ]
  },
  { // Strategy ID 3
    ...defaultDescription,
    supervisor: "The Baked Potato",
    outcomeWatcherAccount: "0xeB52BED34D43c098aA803e1A6Ae286644D09633E",
    info: [`
      Invented by The Baked Potato, the Triple Confirmation Strategy follows the momentum of the market in three easy-to-read indicators: Wolfpack MACD, Market Liberator B waves, and Potato's own in-house signal generator.
      Built with manual trading in mind, The Baked Potato has carefully crafted his strategy to identify shifting trends early and capture entries at the optimal time.
      The Triple Confirmation Strategy can be learned and employed by anyone. Or you can sit back, relax, and sip espresso while the master himself supervises the trades!`,
      `This strategy falls into the Trend Following category of strategies and can best be described as a Trend-Biased Mean Reversion strategy with occasional hedging since both Long and Short positions may be simulatenously open.`
    ]
  },
  { // Strategy ID 4
    ...defaultDescription,
    supervisor: "Will from Trendespresso",
    outcomeWatcherAccount: "0xb815a975cA62fa6f8D60910db8e0c5Aab6a17793",
    info: [`
      Using a confluence of indicators and metrics, Trendespresso can follow the trend for you with this human-traded Bitcoin swing strategy. 
      Similar to the TE Affogato strategy but with full human intervention and outside information analysis. 
      The markets move for a variety of reasons but the charts never lie. Whilst computers alone cannot analyse all outside information, 
      Trendespresso can. Meet the best of both worlds: Automated signal generation meets human filtering. ☕️`,
      `This strategy falls into the Trend Following category of strategies and can best be described as a Trend-Biased Mean Reversion strategy with emphasis on optimising the entry price.`
    ]
  },
  { // Strategy ID 5
    ...defaultDescription,
    category: "Macro",
    asset: "WAVAX",
    info: [`
      Preserve your capital with patient deployment and maximise your chances at profitability. Our macro strategies all follow the larger market trend and can easily last months.
      If you join a macro strategy using Pooled mode, we will attempt to cycle trades quarterly to ensure deposits and withdrawals can be processed multiple times intra-year. As a reminder, Individual mode always allows for immediate deposits and immediate withdrawals.
      Macro strategies are a much more secure way to gain exposure to cryptoassets and ride the rocket 🚀`,
      `Partial entries and partial exits each over an extended period of time to average into the money at opposite ends of the market cycle are key to this strategy. This is a Macro strategy and is heavily focused on patient time-based trading. Average into a Long position at the bottom, then average into a Short position at the top.`
    ]
  },
];

export const DEFAULT_STRATEGY_INDEX = 3; // 1 for Triple Confirmation Pooled
export const DEFAULT_STRATEGY_INDEX_TRADE_PAGE = 6; // do not remove this!!

export const strategies: IStrategy[] = [
  {
    name: "BTC-USD :: Triple Confirmation Strategy :: Individual",
    num: 3,
    isPooledFund: false,
    description: strategyDescriptions[3]
  },
  {
    name: "BTC-USD :: Triple Confirmation Strategy :: Pooled",
    num: 3,
    isPooledFund: true,
    description: strategyDescriptions[3]
  },
  {
    name: "BTC-USD :: TE A Swing :: Individual",
    num: 1,
    isPooledFund: false,
    description: strategyDescriptions[1]
  },
  {
    name: "BTC-USD :: TE A Swing :: Pooled",
    num: 1,
    isPooledFund: true,
    description: strategyDescriptions[1]
  },
  {
    name: "BTC-USD :: Macro :: Individual",
    num: 2,
    isPooledFund: false,
    description: strategyDescriptions[2]
  },
  {
    name: "BTC-USD :: Macro :: Pooled",
    num: 2,
    isPooledFund: true,
    description: strategyDescriptions[2]
  },
  {
    name: "BTC-USD :: Swing :: Individual",
    num: 4,
    isPooledFund: false,
    description: strategyDescriptions[4]
  },
  {
    name: "BTC-USD :: Swing :: Pooled",
    num: 4,
    isPooledFund: true,
    description: strategyDescriptions[4]
  },
];