import React from "react";
import { Footer } from "src/pages/Footer";
import { Header } from "src/pages/Header";
import { PageTemplate } from "src/pages/PageTemplate";
import { AcceptedFCAContext } from "src/global/contexts";

export function Page() {
  const { onFcaNoticeAccepted } = React.useContext(AcceptedFCAContext);

  return (
    <div className="etx-app-wrapper">
      <Header title="FCA Risk Notification" />

      <div className="FCANoticeBody">
        <PageTemplate
          activeRoute={"/fca"}
          content={<FcaApprovePage onFcaNoticeAccepted={onFcaNoticeAccepted} />}
          sidebar={<></>}
        />
      </div>

      <Footer />
    </div>
  );
}

interface IProps {
  onFcaNoticeAccepted: () => void;
}

const _defaultProps: IProps = {
  onFcaNoticeAccepted: () => console.warn("onFcaNoticeAccepted not bound in Fca Page")
};

export function FcaApprovePage(props = _defaultProps) {
  const [acceptedCondition1, setAcceptedCondition1] = React.useState(false);
  const [acceptedCondition2, setAcceptedCondition2] = React.useState(false);
  const [acceptedCondition3, setAcceptedCondition3] = React.useState(false);
  const [acceptedCondition4, setAcceptedCondition4] = React.useState(false);

  function onCondition1Clicked(e: React.ChangeEvent<HTMLInputElement>) {
    setAcceptedCondition1(e.target.checked);
  }

  function onCondition2Clicked(e: React.ChangeEvent<HTMLInputElement>) {
    setAcceptedCondition2(e.target.checked);
  }

  function onCondition3Clicked(e: React.ChangeEvent<HTMLInputElement>) {
    setAcceptedCondition3(e.target.checked);
  }

  function onCondition4Clicked(e: React.ChangeEvent<HTMLInputElement>) {
    setAcceptedCondition4(e.target.checked);
  }

  function confirm() {
    if (acceptedCondition1 && acceptedCondition2 && acceptedCondition3 && acceptedCondition4) {
      props.onFcaNoticeAccepted();
    }
  }

  return (
    <div className="etx-container Container pt-0 pb-0">
      <div className="etx-center">
        <div className="etx-flex Flex a-center">
          <div className="etx-flex__item Flex__item desk-s:c-8">
            <div className="etx-card Card bg-dark-1-60 border-radius overflow-hidden">
              <div className="etx-card__item Card__item tab-s:ph-200 tab-s:pt-200 tab-s:pb-200">
                <div className="etx-text Text">
                  <h2>FCA Compliance</h2>
                  <p style={{ fontSize: 40 }}></p>

                  <p>Trendespresso endeavours to adhere to all applicable Financial Conduct Authority (FCA) regulations, the Financial Services and Markets Act 2000 (FSMA), and the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017.</p>
                  <p>Trendespresso takes compliance with financial laws very seriously and has already taken up representation in the UK to solicit legal advice. We believe that well-regulated industries are essential for a well-functioning society.</p>
                  <p>Our non-custodial, fully-managed trading service is committed to operating in compliance with all applicable laws and regulations. We are passionate about pushing forward decentralisation in the crypto space as a means of preventing criminal activity and scams. Our smart contract system ensures users are in full control of their funds, with all transactions transparent and auditable.</p>

                  <p>We believe that our business falls outside the scope of the Joint Money Laundering Steering Group Guidance from July 2020, as our smart contract system is fully non-custodial, decentralised, community-operated, and puts users in full control. We are a financial technology software business; not a bank, broker, or centralised cryptoasset entity.</p>
                  <p>We understand that blockchain technology presents new challenges for financial compliance, sanctions, anti-money laundering rules, and counter-terrorism funding. We have implemented a Sanctioned Users list as a sign of good faith and are committed to working with the FCA and other government officials to ensure that we do not provide any financial support to terrorists while fostering innovation in the United Kingdom.</p>
                  <p>Your use of this dApp is at your own risk. By doing so, you release Trendespresso, its creators, developers, and contributors from liability arising from any reliance on the information or services provided. You further acknowledge and grant indemnity to Trendespresso for any loss of funds. Investments in cryptoassets carry inherent risks, including price volatility, regulatory uncertainty, and potential loss of funds. You should consult a qualified professional prior to making financial, investment, or cryptoasset-related decisions.</p>
                  <p>Trendespresso, its creators, developers, and contributors may hold positions in the cryptocurrencies, products, or services mentioned and their opinions do not reflect the views of their employers or affiliated organisations.</p>
                </div>

                <br></br>
                <br></br>

                <div className="etx-text Text">
                  <h3>Financial Promotions Disclosure</h3>
                  <p style={{ fontSize: 40 }}></p>

                  <h4>Introduction to Trendespresso</h4>
                  <p>Trendespresso is a beacon of integrity and commitment in financial technology. We're dedicated to following strict standards that align with various legal regulations.</p>
                  <p><b>Regulations We Adhere To:</b><br></br>At the forefront of our practices are the regulations set by the Financial Conduct Authority (FCA), the stipulations of the Financial Services and Markets Act 2000 (FSMA), and the guidelines of the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017.</p>
                  <p><b>Our Belief in Regulation:</b><br></br>We firmly believe that a well-regulated industry is pivotal for maintaining trust and ensuring the smooth operation of any society. This conviction drives our commitment to compliance and ethical operations.</p>
                  <p><b>Our Services:</b><br></br>We offer a non-custodial decentralised automated trading service. In simpler terms, this means our platform is designed to work without holding onto your funds directly. Everything operates transparently and autonomously, which ensures maximum security and control for our users.</p>
                  <p><b>Our Stand on Decentralisation:</b><br></br>We're not just a business but advocates for change. We passionately push for decentralisation in crypto as it plays a pivotal role in preventing criminal activities and fraudulent schemes.</p>
                  <p><b>User Control & Transparency:</b><br></br>Users have the wheel with our smart contract system. They maintain complete control over their funds; every transaction is transparent and can be verified for authenticity.</p>

                  <p style={{ fontSize: 40 }}></p>

                  <h4>Important Considerations for Cryptoasset Investments:</h4>
                  <p>The FCA has highlighted certain risks associated with cryptoasset investments. If you're considering this investment avenue, here are some key points to keep in mind:</p>
                  <p><b>Volatility of Cryptoassets:</b><br></br>Like a roller coaster, the value of most cryptoassets can shoot up or plummet rapidly. This means there's a real risk of losing your entire investment.</p>
                  <p><b>Safety Nets Don't Apply:</b><br></br>Traditional safety mechanisms like the Financial Services Compensation Scheme (FSCS) don't cover cryptoasset investments. Similarly, if you have complaints, the Financial Ombudsman Service (FOS) won't entertain them as they do with conventional investments.</p>
                  <p><b>Selling Challenges:</b><br></br>Imagine wanting to sell an asset, but no one's buying. That's a possibility with cryptoassets. Their sale isn't always guaranteed due to fluctuating market demands and other factors.</p>
                  <p><b>Complex Nature:</b><br></br>Cryptoassets aren't simple. They're multifaceted and can be challenging to understand. Please always prioritize research and education before you dive in.</p>
                  <p><b>Not All Are Created Equal:</b><br></br>Different cryptoassets have unique risks. It's crucial to differentiate and understand these before making any investment decisions.</p>
                  <p><b>Diversify Your Portfolio:</b><br></br>A timeless piece of advice - don't put all your eggs in one basket. Investing all your money in a single type of asset, especially one as unpredictable as cryptoassets is risky.</p>

                  We highly recommend visiting the FCA's website for a comprehensive understanding and additional resources. They offer an array of insights and protective measures for prospective investors.

                  <p style={{ fontSize: 40 }}></p>

                  <h3>For more information, visit:</h3>
                  <p style={{ fontSize: 19 }}><li><a href="https://wiki.trendespresso.com/">UK Risk Disclaimer</a></li></p>
                  <p style={{ fontSize: 19 }}><li><a href="https://www.fca.org.uk/investsmart/crypto-basics">FCA</a></li></p>
                  <p style={{ fontSize: 19 }}><li><a href="https://www.fscs.org.uk/check/investment-protection-checker/">FSCS</a></li></p>
                  <p style={{ fontSize: 19 }}><li><a href="https://www.financial-ombudsman.org.uk/consumers">FOS</a></li></p>

                  <p style={{ fontSize: 14 }}>Kraken has an excellent overview of the risks and more information on each major crypto asset. We highly recommend you check each asset in this dApp to which you may have exposure:</p>
                  <p style={{ fontSize: 14 }}><a href="https://www.kraken.com/legal/uk/asset-statements">Kraken Asset Statements</a></p>
                </div>
              </div>

              <div className="etx-card__item Card__item ph-0 pt-0 pb-50">
                <hr className="dotted" />
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 pb-0">
                <div className="FormField">
                  <div className="FormField__input checkbox">
                    <input className="cursorAsHand" type="checkbox" id="disclaimer-condition-1" checked={acceptedCondition1} onChange={onCondition1Clicked} />
                    <label htmlFor="disclaimer-condition-1">I hereby confirm that I have read and understand this FCA Compliance Disclaimer.</label>
                  </div>
                </div>
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 pb-0">
                <div className="FormField">
                  <div className="FormField__input checkbox">
                    <input className="cursorAsHand" type="checkbox" id="disclaimer-condition-2" checked={acceptedCondition2} onChange={onCondition2Clicked} />
                    <label htmlFor="disclaimer-condition-2">I recognise that engaging with cryptoassets entail inherent risks, including price volatility, regulatory ambiguity, and the potential loss of funds.</label>
                  </div>
                </div>
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 pb-0">
                <div className="FormField">
                  <div className="FormField__input checkbox">
                    <input className="cursorAsHand" type="checkbox" id="disclaimer-condition-3" checked={acceptedCondition3} onChange={onCondition3Clicked} />
                    <label htmlFor="disclaimer-condition-3">I agree that <span style={{ fontSize: "0.95em" }}>Ŧ</span>E Token is a Utility Token and is not intended to function as an investment vehicle.</label>
                  </div>
                </div>
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 pb-0">
                <div className="FormField">
                  <div className="FormField__input checkbox">
                    <input className="cursorAsHand" type="checkbox" id="disclaimer-condition-4" checked={acceptedCondition4} onChange={onCondition4Clicked} />
                    <label htmlFor="disclaimer-condition-4">I hereby hold harmless and release Trendespresso and each of its members, founders, owners, employees, and contractors of any and all liability.</label>
                  </div>
                </div>
              </div>

              <div className="etx-card__item Card__item tab-s:ph-200 tab-s:pv-200">
                <div className="etx-button-list ButtonList">
                  {acceptedCondition1 === false || acceptedCondition2 === false || acceptedCondition3 === false || acceptedCondition4 === false
                    ?
                    <a className="etx-button Button fullwidth negative" title="Please accept the notice" style={{ cursor: "not-allowed" }}>
                      <div className="etx-button__icon Button__icon">
                        <i className="fal fa-ban"></i>
                      </div>
                      <span className="etx-button__text Button__text">Please confirm you have read and agreed to all conditions</span>
                    </a>
                    :
                    <a className="etx-button Button fullwidth positive" title="Confirm" onClick={() => confirm()}>
                      <div className="etx-button__icon Button__icon">
                        <i className="fal fa-check"></i>
                      </div>
                      <span className="etx-button__text Button__text">Confirm</span>
                    </a>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
