import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import React from "react";
import { AcceptedFCAContext, StrategyContext } from "src/global/contexts";
import { strategies } from "src/global/strategies";
import { PartnerLogos } from "src/pages/PartnerLogos";

interface IProps {
  title: string;
  description?: ReactJSXElement;
}

const defaultProps: IProps = {
  title: "Default_Title",
  description: <PartnerLogos />,
};

export function Header(props = defaultProps) {
  const { selectedStrategy, setStrategy } = React.useContext(StrategyContext);
  const { acceptedFcaNotice } = React.useContext(AcceptedFCAContext);

  return (
    <header
      id={acceptedFcaNotice ? undefined : "FCANoticeTitleWrapper"}
      className="etx-header Header extraPaddingSmallScreen"
    >
      <div className="etx-header__row Header__row centered">
        <div className={`etx-flex Flex c-auto av-bottom gv-25 gh-50${acceptedFcaNotice ? "" : " FCANoticeTitle"}`}>
          <div className="etx-flex__item Flex__item">
            <div className="etx-text Text">
              <h1>{props.title}</h1>
            </div>
          </div>

          <div className="etx-flex__item Flex__item">
            <div className="etx-text Text">
              <div className="font-size-larger" style={{ paddingTop: "5px", paddingBottom: "5px" }}>{props.description ?? ""}</div>
            </div>
          </div>

          {
            acceptedFcaNotice
              ? <div className="strategySelector">
                {/* style={{ paddingRight: "380px" }} */}
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="FormField">
                    <div className="FormField__input select">
                      {
                        /*
                          https://developer.mozilla.org/docs/Web/CSS/cursor
                          AVAILABLE CURSOR VALUES
                          .auto          { cursor: auto; }
                          .default       { cursor: default; }
                          .none          { cursor: none; }
                          .context-menu  { cursor: context-menu; } --> short list icon
                          .help          { cursor: help; } --> question mark
                          .pointer       { cursor: pointer; } --> hand
                          .progress      { cursor: progress; } --> spinning blue wheel
                          .wait          { cursor: wait; } --> spinning blue wheel
                          .cell          { cursor: cell; } --> white plus sign (similar to Excel)
                          .crosshair     { cursor: crosshair; }
                          .text          { cursor: text; } --> highlight text cursor
                          .vertical-text { cursor: vertical-text; } --> rotates the highlight text cursor 90 degrees
                          .alias         { cursor: alias; } --> curved arrow (as shown on Aliases in macOS Finder)
                          .copy          { cursor: copy; } --> green plus sign
                          .move          { cursor: move; } --> four black arrows in each cardinal direction
                          .no-drop       { cursor: no-drop; } --> do not enter sign
                          .not-allowed   { cursor: not-allowed; } --> do not enter sign
                          .grab          { cursor: grab; } --> open hand as if grabbing a cell in Excel
                          .grabbing      { cursor: grabbing; } --> closed hand as if holding a cell in Excel
                          .all-scroll    { cursor: all-scroll; }
                          .col-resize    { cursor: col-resize; }
                          .row-resize    { cursor: row-resize; }
                          .n-resize      { cursor: n-resize; }
                          .e-resize      { cursor: e-resize; }
                          .s-resize      { cursor: s-resize; }
                          .w-resize      { cursor: w-resize; }
                          .ns-resize     { cursor: ns-resize; }
                          .ew-resize     { cursor: ew-resize; }
                          .ne-resize     { cursor: ne-resize; }
                          .nw-resize     { cursor: nw-resize; }
                          .se-resize     { cursor: se-resize; }
                          .sw-resize     { cursor: sw-resize; }
                          .nesw-resize   { cursor: nesw-resize; }
                          .nwse-resize   { cursor: nwse-resize; }
                        */
                      }
                      <select
                        name="Strategy"
                        id="strategy"
                        style={{ cursor: "pointer" }}
                        defaultValue={strategies.findIndex(strategy => strategy.name === selectedStrategy.name)}
                        onChange={(e) => setStrategy(strategies[Number(e.target.value)])}
                      >
                        {strategies?.map((x, i) => <option key={x.name} value={i}>{x.name}</option>) ?? <></>}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              : <></>
          }
        </div>
      </div>
    </header>
  );
}
