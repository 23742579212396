import React from "react";
import { limitDecimals, strTrunc } from "src/global/utils";
import { useWeb3Modal } from "@web3modal/react";
import { ContractsManagerContext, FunctionsContext, WalletContext } from "src/global/contexts";

// const metaMaskLogo: string = require("src/assets/img/logo_metamask.svg").default;
// const coreLogo: string = require("src/assets/img/logo_brand_core-wallet.svg").default;
// const coinbaseLogo: string = require("src/assets/img/logo_brand_coinbase.svg").default;
const walletConnectLogo: string = require("src/assets/img/logo_WalletConnect.svg").default;

export function WalletProviderDrawer() {
  const { isOpen, open } = useWeb3Modal();

  const { wallet } = React.useContext(WalletContext);
  const { setWalletConnectionState, setLoader } = React.useContext(FunctionsContext);
  const { contractManager } = React.useContext(ContractsManagerContext);

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [hasOpened, setHasOpened] = React.useState(false);
  const [shouldCheckLoading, setShouldCheckLoading] = React.useState(false);
  const [walletInfo, setWalletInfo] = React.useState("");
  const [walletTitle, setWalletTitle] = React.useState("");

  const onClick = () => {
    if (wallet.connectionState === "INIT") {
      setWalletConnectionState("CONNECTING");

      // Milliseconds until the . . . should begin after clicking Connect Wallet button and WalletConnect / MetaMask hasn't popped up yet
      window.setTimeout(() => setShouldCheckLoading(true), 1500);

      // Simulates 3 seconds of wait time for WalletConnect
      // setTimeout(open, 3000);

      // Uncomment before uploading
      open();
    }
    else if (wallet.connectionState === "CONNECTED") {
      setIsExpanded(!isExpanded);
    }
  };

  const defaultWalletText = () => {
    if (wallet.connectionState === "INIT") {
      if (window.innerWidth < 1500 && window.innerWidth >= 1000) {
        setWalletInfo("Connect");
      }
      else {
        setWalletInfo("Connect Wallet");
      }
    }
  };

  React.useEffect(() => {
    // Modal was opened
    if (isOpen && !hasOpened) {
      setHasOpened(true);
      setLoader(false);
    }
    // Modal was closed
    else if (!isOpen && hasOpened && wallet.connectionState !== "CONNECTED") {
      setWalletConnectionState("INIT");
      setHasOpened(false);
      setLoader(false);
    }
  }, [hasOpened, isOpen, setLoader, setWalletConnectionState, wallet.connectionState]);

  React.useEffect(() => {
    const updateWalletTitle = () => {
      if (window.innerWidth > 1500) {
        setWalletInfo(strTrunc(wallet.address, 12, 10));
      }
      else {
        setWalletInfo(strTrunc(wallet.address, 7, 5));
      }
    };

    if (wallet.connectionState === "INIT") {
      const walletSelector = document.getElementById("walletSelectorCursor");

      if (walletSelector) {
        walletSelector.style["cursor"] = "pointer";
      }

      defaultWalletText(); // Run the function once on page load.
      setWalletTitle("Wallet address");
    }
    else if (wallet.connectionState === "CONNECTING") {
      const walletSelector = document.getElementById("walletSelectorCursor");

      if (walletSelector) {
        walletSelector.style["cursor"] = "hide";
      }

      setWalletInfo("Connecting . . .");
      setWalletTitle("Wallet address");
    }
    else if (wallet.connectionState === "CONNECTED") {
      const walletSelector = document.getElementById("walletSelectorCursor");

      if (walletSelector) {
        walletSelector.style["cursor"] = "pointer";
      }

      updateWalletTitle();
      window.addEventListener("resize", updateWalletTitle);
      setWalletTitle(wallet.address);
    }

    window.addEventListener("resize", defaultWalletText);

    return () => {
      window.removeEventListener("resize", updateWalletTitle);
      window.removeEventListener("resize", defaultWalletText);
    };
  }, [setLoader, wallet.address, wallet.connectionState]);

  React.useEffect(() => {
    // Modal not yet loaded, show loader
    if (shouldCheckLoading && wallet.connectionState === "CONNECTING" && !hasOpened) {
      setShouldCheckLoading(false);
      setLoader(true);
    }
    else if (shouldCheckLoading) {
      setShouldCheckLoading(false);
    }
  }, [hasOpened, setLoader, shouldCheckLoading, wallet.connectionState]);

  // For debugging
  // React.useEffect(() => console.log(wallet.connectionState), [wallet.connectionState]);

  // Pick from: https://fontawesome.com/search?q=switch&o=r
  // Source: https://fontawesome.com/icons/shuffle?f=classic&s=duotone&rt=flip-vertical
  const switchNetworkIcon = "fa-duotone fa-shuffle fa-flip-vertical"; // "shuffle";

  return (
    <div
      className={`MetaMask${wallet.connectionState !== "CONNECTED" ? " warning" : ""}${isExpanded ? " active" : ""}`}
      onClick={onClick}
    >
      <div className="MetaMask__title" id="walletSelectorCursor">
        <div className="MetaMask__title__icon">
          <img src={walletConnectLogo} alt="WalletConnect" />
        </div>
        <div className="MetaMask__title__text">
          <p className="font-weight-medium etx-text-crop line-1" title={walletTitle}>
            {walletInfo}
          </p>
        </div>
        <div className="MetaMask__title__angle">
          <i className="fal fa-angle-up"></i>
        </div>
      </div>
      <div className="MetaMask__content">
        <div className="etx-card Card">
          <div className="etx-card__item Card__item pv-50">
            <div className="etx-flex Flex c-auto av-center gv-0">
              <div className="etx-flex__item Flex__item shrink-0">
                <div className="etx-text Text">
                  <p className="font-size-small font-color-white-2">{contractManager.cash.name}</p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="etx-text Text a-right">
                  <p className="font-size-large font-weight-medium">
                    {limitDecimals(wallet.balances.cash.toNumber() / contractManager.cash.denominator.toNumber(), contractManager.cash.decimals.toNumber())}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <hr />
          </div>

          <div className="etx-card__item Card__item pv-50">
            <div className="etx-flex Flex c-auto av-center gv-0">
              <div className="etx-flex__item Flex__item shrink-0">
                <div className="etx-text Text">
                  <p className="font-size-small font-color-white-2"><span style={{ fontSize: "0.95em" }}>Ŧ</span>E</p>
                </div>
              </div>

              <div className="etx-flex__item Flex__item grow-1">
                <div className="etx-text Text a-right">
                  <p className="font-size-large font-weight-medium">
                    {limitDecimals(wallet.balances.TE.toNumber() / contractManager.contracts.TE.denominator.toNumber(), contractManager.contracts.TE.decimals.toNumber())}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="etx-card__item Card__item ph-0 pv-0">
            <hr />
          </div>

          <div className="etx-card__item Card__item pv-50">
            <div className="etx-button-list ButtonList">
              <button className="etx-button Button fullwidth positive" onClick={() => open()}>
                <div className="etx-button__icon Button__icon">
                  <i className="fal fa-check-cross"></i>
                </div>
                <div className="etx-button__text Button__text" style={{ width: "150px" }}>
                  Blockchain
                  <i className={switchNetworkIcon} style={{ marginLeft: "12px" }}></i>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
