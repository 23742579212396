import React from "react";
import { Funds } from "src/pages/strategy/sidebar/Funds";
import { Status } from "src/pages/strategy/sidebar/Status";
import { Info } from "src/pages/strategy/sidebar/Info";
import { SidebarTemplate } from "src/pages/SidebarTemplate";

export const elements = [
  <Funds />,
  <Info layout="strategy" />,
  <Status />
];

export function Sidebar() {
  return <SidebarTemplate
    elements={elements}
  />;
}
