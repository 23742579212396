import React from "react";
import { Footer } from "src/pages/Footer";
import { Header } from "src/pages/Header";
import { PageTemplate } from "src/pages/PageTemplate";
import { Sidebar, defaultProps, IProps } from "src/pages/memberships/Sidebar";
import { AcceptedFCAContext, ContractsManagerContext, DialogContext, StrategyContext, WalletContext } from "src/global/contexts";
import { DepositWithdraw } from "src/pages/strategy/dialogs/DepositWithdraw";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { IPctPositionsObj, ISystemSummaryObj, defaultSystemSummary } from "src/global/types";
import { strategies, strategyDescriptions } from "src/global/strategies";
import { BASE_LEARN_MORE_URL } from "src/global/constants";
import { colorPnL, fixSolidityStruct, formatUsdc, formatUsdcPnL, getTradeStatus } from "src/global/utils";
import { useWeb3Modal } from "@web3modal/react";
import { PartnerLogos } from "../PartnerLogos";

const te_logo: string = require("src/assets/img/TE_Logo_v4.png");

const strategy1_icon: string = require('src/assets/img/strategy-icons/number-1.svg').default;
const strategy2_icon: string = require('src/assets/img/strategy-icons/number-2.svg').default;
const strategy3_icon: string = require('src/assets/img/strategy-icons/number-3.svg').default;
const strategy4_icon: string = require('src/assets/img/strategy-icons/number-4.svg').default;
const strategy5_icon: string = require('src/assets/img/strategy-icons/number-5.svg').default;

interface IPropsBody {
  setSidebarInfo: (a: IProps) => void;
}

const defaultPropsBody: IPropsBody = {
  setSidebarInfo: (a) => console.warn("setSidebarInfo not bound in Membership Page")
};

// TRADING BOT PAGE
export function Page() {
  const { dialogState } = React.useContext(DialogContext);
  const { acceptedFcaNotice } = React.useContext(AcceptedFCAContext);

  const [sidebarInfo, setSidebarInfo] = React.useState({ ...defaultProps });

  const navigate = useNavigate();

  React.useEffect(() => {
    if (acceptedFcaNotice === false) {
      navigate("/");
    }
  }, [acceptedFcaNotice, navigate]);

  return (
    <div className={`etx-app-wrapper ${dialogState === "OPEN" ? "overlay-show" : ""}`}>
      <Header title="Memberships" description={<PartnerLogos />} />

      <PageTemplate
        activeRoute="/memberships"
        content={<Body setSidebarInfo={setSidebarInfo} />}
        sidebar={<Sidebar totalUsdValue={sidebarInfo.totalUsdValue} totalUsdPnl={sidebarInfo.totalUsdPnl} />}
      />

      <Footer />
      {dialogState === "OPEN" ? <DepositWithdraw /> : <></>}
    </div>
  );
}

function Body(props = defaultPropsBody) {
  const { contractManager } = React.useContext(ContractsManagerContext);
  const { wallet } = React.useContext(WalletContext);
  const { setStrategy } = React.useContext(StrategyContext);

  const { open } = useWeb3Modal();
  const openModal = () => {
    open();
  };
  const navigate = useNavigate();

  function selectImageBasedOnStrategyNum(num: number) {
    function generateImg(src: string) {
      return <img src={src} style={{ filter: "invert(60%) brightness(100%)" }} />;
    }
    switch (num) {
      case 1: return generateImg(strategy1_icon);
      case 2: return generateImg(strategy2_icon);
      case 3: return generateImg(strategy3_icon);
      case 4: return generateImg(strategy4_icon);
      case 5: return generateImg(strategy5_icon);
    }
    return <img src={te_logo}></img>;
  }

  const [membershipsArr, setMembershipsArr] = React.useState([{ systemSummary: { ...defaultSystemSummary }, totalUsdValue: 0, totalUsdPnl: 0 }]);

  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  React.useEffect(() => {
    strategyValues();
  }, []);

  const getStrategyCount = async () => {
    while (contractManager.contracts.Controller.functions === undefined) {
      await timeout(1000); // for 1 sec delay
    }
    return (await contractManager.contracts.Controller.functions.getStrategyCount()) as ethers.BigNumber;
  };

  const strategyValues = async () => {
    const strategyCount = await getStrategyCount();
    while (contractManager.contracts.Frontend.functions === undefined) {
      await timeout(1000); // for 1 sec delay
    }
    const membershipsArrTmp = [{ systemSummary: { ...defaultSystemSummary }, totalUsdValue: 0, totalUsdPnl: 0 }];
    for (let i = 1; strategyCount.gte(i); ++i) {
      const systemSummary: ISystemSummaryObj = await contractManager.contracts.Frontend.functions.getSystemSummaryOf(wallet.address, i);

      let totalUsdValue = (
        systemSummary.Account.tradingEngineInfo.totalCashValue
          .add(systemSummary.PooledFund.memberBalance.current)).toNumber();

      let totalUsdPnl = 0
        - Number(systemSummary.Account.contractBalance.pretrade)
        - systemSummary.PooledFund.memberBalance.pretrade
        + Number(systemSummary.Account.contractBalance.current)
        + systemSummary.Account.contractBalance.pnl
        + systemSummary.PooledFund.memberBalance.pnl
        + systemSummary.PooledFund.memberBalance.current;

      if (!systemSummary.Strategy.cashSymbol.toUpperCase().includes("USD")) {
        while (contractManager.contracts.DepositWithdraw.functions === undefined) {
          await timeout(1000); // for 1 sec delay
        }

        const aaveDenominator = (await contractManager.contracts.DepositWithdraw.functions.aaveDenominator()) as ethers.BigNumber;
        const aavePriceOfCash = (await contractManager.contracts.DepositWithdraw.functions.getPriceOf(systemSummary.Strategy.cashSymbol)) as ethers.BigNumber;

        totalUsdValue = (
          aavePriceOfCash
            .mul(totalUsdValue)
            .div(aaveDenominator)
        ).toNumber();

        totalUsdPnl = (
          aavePriceOfCash
            .mul(totalUsdPnl)
            .div(aaveDenominator)
        ).toNumber();
      }

      membershipsArrTmp.push({
        systemSummary,
        totalUsdValue,
        totalUsdPnl
      });

      membershipsArrTmp[0].totalUsdValue += totalUsdValue;
      membershipsArrTmp[0].totalUsdPnl += totalUsdPnl;
    }
    setMembershipsArr(membershipsArrTmp);
    props.setSidebarInfo({
      totalUsdValue: membershipsArrTmp[0].totalUsdValue,
      totalUsdPnl: membershipsArrTmp[0].totalUsdPnl,
    });
  };

  const getTradeStatusWrapper = async (account: string) => {
    if (contractManager.contracts.DepositWithdraw.functions === undefined) {
      return "undefined";
    }
    return getTradeStatus(fixSolidityStruct(await contractManager.contracts.DepositWithdraw.functions.getPctPositionsOf(account) as IPctPositionsObj));
  };

  const membershipInfoCard = (s: ISystemSummaryObj, field: string, value: any) => {
    if (field === "Current Trade") {
      value =
        s.Account.rawValues.totalCashValue.gt(0)
          ? getTradeStatus(s.Account.pctPositions)
          : s.PooledFund.memberBalance.current > 0
            ? getTradeStatus(s.PooledFund.pctPositions)
            : "Not a member";
    }
    return (<>
      <div className="etx-card__item Card__item pv-50">
        <div className="etx-flex Flex c-auto av-center gh-50 gv-0 membershipPageGutter">
          <div className="etx-flex__item Flex__item shrink-0">
            <div className="etx-text Text">
              <p className="font-label">
                {field}
              </p>
            </div>
          </div>

          <div className="etx-flex__item Flex__item grow-1">
            <div className="etx-text Text a-right">
              <p className="font-size-medium font-weight-medium" style={field.toLowerCase().includes("pnl") ? colorPnL(Number(value)) : {}}>
                {value}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
        <hr />
      </div>
    </>);
  };

  const htmlMembership = (i: number, s: ISystemSummaryObj, totalUsdValue: number, totalUsdPnl: number) => {
    const openInNewTab = (num: number) => {
      window.open(BASE_LEARN_MORE_URL + num.toString(), '_blank', 'noopener,noreferrer');
    };

    function getClosestSubscriptionDate(s: ISystemSummaryObj) {
      const timestamp =
        s.Account.contractBalance.pretrade.gt(0)
          ? s.PooledFund.memberBalance.pretrade > 0
            ? s.Account.subscription.paidThru.lt(s.PooledFund.subscription.paidThru)
              ? s.Account.subscription.paidThru
              : s.PooledFund.subscription.paidThru
            : s.Account.subscription.paidThru
          : s.PooledFund.subscription.paidThru;
      console.log("timestamp", timestamp);
      return new Date(timestamp.toNumber() * 1000).toLocaleDateString();
    }

    return (<>{
      i === 0 || totalUsdValue === 0
        ? <></>
        :
        <div className="etx-flex__item Flex__item" style={{ width: "28.3%" }}>
          <div className="etx-card Card bg-dark-1-60 border-radius overflow-hidden">
            {/* <div className="etx-card__item Card__item ph-0 pv-50" onClick={(e) => { props.setFullImage('silver') }}> */}
            <div className="etx-card__item Card__item ph-0 pv-50">
              {selectImageBasedOnStrategyNum(i)}
            </div>

            <div className="etx-card__item Card__item ph-0 pv-0">
              <hr className="dotted" />
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200">
              <div className="etx-text Text">
                <h3>{s.Strategy.strategyInfo1}</h3>
                {/* <p>USD Value: <b>{totalUsdValue ?? 0}</b></p> */}
                <p>{s.Strategy.strategyInfo2}</p>
              </div>
            </div>

            <div className="etx-card__item Card__item ph-0 pt-0 pb-50">
              <hr className="dotted" />
            </div>

            <>{
              [
                { field: "USD Value", value: formatUsdc(totalUsdValue) },
                { field: "USD PnL", value: formatUsdcPnL(totalUsdPnl) },
                { field: "Trader Type", value: strategyDescriptions[i].traderType },
                { field: "Current Trade", value: strategyDescriptions[i].outcomeWatcherAccount },
                { field: "Subscription", value: getClosestSubscriptionDate(s) },
              ].map((c, i) => membershipInfoCard(s, c.field ?? undefined, c.value ?? undefined))
            }</>

            {/* <>{buildSelectedStrategyStatus()}</> */}

            <div className="etx-card__item Card__item pv-50 strategyInfo_hover" onClick={() => openInNewTab(i)}>
              <div className="etx-flex Flex c-auto av-center gh-50 gv-0 membershipPageGutter">
                <div className="etx-flex__item Flex__item shrink-0">
                  <div className="etx-text Text">
                    <p className="font-label">View more on the Wiki</p>
                  </div>
                </div>

                <div className="etx-flex__item Flex__item grow-1">
                  <div className="etx-text Text a-right">
                    <i className="font-label far fa-info-circle"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 pv-0">
              <hr />
            </div>

            <div className="etx-card__item Card__item tab-s:ph-200 tab-s:pb-200">
              <div className="etx-button-list ButtonList">

                <a className="etx-button Button fullwidth disabledElement" style={{ backgroundColor: "gray !important" }}> {/* depositColour */}
                  <div className="etx-button__icon Button__icon">
                    {/* <i className="fal fa-shopping-cart"></i> */}
                    <i className="fal fa-arrow-down-to-arc"></i>
                  </div>
                  <span
                    className="etx-button__text Button__text"
                    onClick={() => setStrategy(strategies[1])}
                  >
                    Deposit
                  </span>
                </a>

                <a className="etx-button Button fullwidth disabledElement"> {/* withdrawColour */}
                  <div className="etx-button__icon Button__icon">
                    {/* <i className="fal fa-shopping-cart"></i> */}
                    <i className="fal fa-arrow-up-from-arc"></i>
                  </div>
                  <span
                    className="etx-button__text Button__text"
                    onClick={() => { }}
                  >
                    Withdraw
                  </span>
                </a>

              </div>
            </div>

          </div>
        </div>
    }</>);
  };

  const htmlForEachMembership = () => {
    return (
      <>
        {
          membershipsArr[0].totalUsdValue === 0
            ? <div className="etx-flex__item Flex__item" style={{ height: "100px", width: "50%", textAlign: "center", justifyContent: "center", placeContent: "center", margin: "auto" }}>
              <div
                className="etx-card Card bg-dark-1-60 border-radius overflow-hidden font-weight-bold font-size-large"
                style={{ cursor: "pointer", textAlign: "center", justifyContent: "center", placeContent: "center", margin: "auto" }}
                onClick={() => {
                  wallet.connectionState === "CONNECTED"
                    // ? window.open(BASE_LEARN_MORE_URL, '_blank', 'noopener,noreferrer')
                    ? navigate("/")
                    : openModal();
                }}
              >
                <div className="etx-card__item Card__item ph-0 pv-50">
                  {
                    wallet.connectionState === "CONNECTED"
                      ? "Deposit into a strategy to get started!"
                      : "Connect your wallet first!"
                  }
                </div>
              </div>
            </div>
            : <div className="etx-flex Flex tab-s:c-2 desk-s:c-3 desk-m:c-4 gh-50" style={{ width: "100%" }}>
              {membershipsArr.map((m, i) => htmlMembership(i, m.systemSummary, m.totalUsdValue, m.totalUsdPnl))}
            </div>
        }
      </>
    );
  };

  return (
    <div className="etx-main">
      <div className="etx-main__body">

        <div className="etx-container Container pt-0 pb-0">
          <div className="etx-center">

            <div className="etx-text Text font-size-large">
              <p>An overview showing all your strategy memberships. Only shows strategies where you have funds deposited.</p>
            </div>

          </div>
        </div>

        <div className="etx-spacer Spacer h-50"></div>

        <div className="etx-container Container pt-0 pb-0">
          <div className="etx-center">
            {htmlForEachMembership()}
          </div>
        </div>

      </div>
    </div>
  );
}

