import React from "react";

export function Footer() {
  return (
    <footer className="etx-footer Footer">
      <div className="etx-footer__row centered">
        <div className="etx-flex Flex c-auto gv-50">
          <div className="etx-flex__item Flex__item grow-1">
            <div className="FooterLinks">
              <a
                href="https://www.trendespresso.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                About Us
              </a>
              <a
                href='https://wiki.trendespresso.com'
                target="_blank"
                rel="noreferrer noopener"
              >
                Wiki
              </a>
              <a
                href="https://snowtrace.io/token/0x77171464ae48a2C96B3b7E513e9EEc5843207E7E?chainId=43114"
                target="_blank"
                rel="noreferrer noopener"
              >
                {/* Ŧ<span style={{rotate: "180deg"}}>Ǝ</span> Token */}
                <span style={{ fontSize: "0.95em" }}>Ŧ</span>E Token
              </a>
              <a
                href="https://pitchdeck.trendespresso.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Pitch Deck
              </a>
              <a
                href="https://businessplan.trendespresso.com"
                target="_blank"
                rel="noreferrer noopener"
              >
                Business Plan
              </a>
              <b>
                <a
                  href="https://find-and-update.company-information.service.gov.uk/company/OC451017"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Trendespresso LLP
                </a>
              </b>
            </div>
          </div>

          <div className="etx-flex__item Flex__item shrink-0">
            <div className="FooterSocials">
              <a
                href="https://discord.com/invite/uUM4ehGZn4"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fab fa-discord"></i>
              </a>
              <a
                // href="https://t.me/+q5gM0XkO9vk0ZTEx"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fab fa-telegram"></i>
              </a>
              <a
                // href="https://twitter.com/tripleconfirmed"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.youtube.com/@trendespresso"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fab fa-youtube"></i>
              </a>
              {/* <a
                href="https://rss.com/podcasts/cryptoyams/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <i className="fa fa-rss-square"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="etx-footer__row"></div>
    </footer>
  );
}
