import React from "react";
import { FunctionsContext, NetworkContext } from "src/global/contexts";
import { FaqAccordion } from "src/pages/FaqAccordion";
import { TE_ADDR, TUTORIAL_URL } from "src/global/constants";

const IMG_Add_token_to_Web3_wallet: string = require('src/assets/img/faq/Add_token_to_Web3_wallet.png');
const IMG_Individual_vs_Pooled: string = require('src/assets/img/faq/Individual_vs_Pooled.png');
const IMG_MetaMask_Blue_Connect_Link: string = require('src/assets/img/faq/MetaMask_Blue_Connect_Link.png');
const IMG_MetaMask_Blue_Connect_Link_Demo_MOV: string = require('src/assets/img/faq/MetaMask_Blue_Connect_Link_Demo.mov');
const IMG_MetaMask_Blue_Connect_Link_Demo_WEBM: string = require('src/assets/img/faq/MetaMask_Blue_Connect_Link_Demo.webm');
const ids = {
  MetaMask_Simulation_Anchor: false
};

export function FaqOverlay() {
  const { setActiveOverlay } = React.useContext(FunctionsContext);
  const { network } = React.useContext(NetworkContext);

  function scrollToJustAbove(id: (keyof typeof ids)) {
    ids[id] = !ids[id]; // toggle the boolean
    if (ids[id]) {
      setTimeout(() => window.location.hash = "#" + id, 1 /* ms */);
    }
  }

  const metaMaskSimulationURL = "https://learn.metamask.io/lessons/what-is-a-crypto-wallet";

  return (
    <div className="Overlay faq">
      <div className="Overlay__background"></div>

      <div
        className="Overlay__close"
        onClick={() => setActiveOverlay(null)}
      >
        <i className="fal fa-times"></i>
      </div>

      <div className="faqSideGutter">
        <div className="Overlay__content">
          <div className="etx-container pt-0 pb-0">
            <div className="etx-center">
              <div className="etx-text Text font-size-large">
                <h3>FAQ</h3>
                To learn more about our platform, please read our FAQ below.{" "}
                <br />
                For more detailed information,<b>
                  {" "}
                  check out our{" "}
                  <a
                    href="https://wiki.trendespresso.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Wiki
                  </a>
                  {/* &nbsp; */}
                </b>.
              </div>
            </div>
          </div>

          <div className="etx-spacer h-25"></div>

          <div className="etx-container pt-0 pb-0">
            <div className="etx-center">
              <hr />
            </div>
          </div>

          <div className="etx-spacer h-50 disappearAtNarrowWindowWidth"></div>

          <div className="etx-container pt-0 pb-0">
            <div className="etx-center">
              <div className="etx-flex Flex">
                <div className="etx-flex__item Flex__item tab-m:c-2 tab-m:ml-1 tab-m:o-2"></div>

                <div className="etx-flex__item Flex__item"> {/* used to include this in the ``className``: tab-m:c-9 */}
                  <div className="etx-text Text">
                    <h5>Tutorial</h5>
                  </div>

                  <div className="etx-spacer h-25"></div>

                  <div className="etx-video Video">
                    <iframe
                      width="560"
                      height="315"
                      src={TUTORIAL_URL}
                      title="Trendespresso Tutorial"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen={true}
                    ></iframe>

                  </div>
                </div>

                <div className="etx-flex__item Flex__item"> {/* used to include this in the ``className``: tab-m:c-9 */}
                  <div className="etx-text Text">
                    <h5>Set up your wallet</h5>
                  </div>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="How do I install MetaMask?">
                    <>
                      <div className="etx-text Text">
                        <p>
                          Available as a browser extension and as a mobile app,
                          MetaMask equips you with a key vault, secure login, token
                          wallet, and token exchange - everything you need to manage
                          your digital assets. MetaMask provides the simplest yet
                          most secure way to connect to blockchain-based
                          applications. You are always in control when interacting
                          on the new decentralised web. MetaMask generates passwords
                          and keys on your device, so only you can access your
                          accounts and data. You always choose what to share and
                          what to keep private. Check out Metamask Support for
                          more information and help. Make sure to read and
                          understand Metamask's Privacy Policy and its Terms of Use.
                        </p>
                        <p>
                          Check out the open source{" "}
                          <a
                            href="https://github.com/MetaMask/metamask-sdk"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            MetaMask repository on Github.
                          </a>
                        </p>
                      </div>

                      <div className="etx-button-list ButtonList">
                        <a
                          href="https://metamask.io/download/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="etx-button Button faqButtons"
                        // style={{
                        //   backgroundColor: "var(--color-faq-wallet-1)",
                        //   transition: "background-color 0.4s var(--transition-timing)"
                        // }}
                        >
                          <div className="etx-button__icon Button__icon">
                            <i className="fal fa-arrow-right"></i>
                          </div>
                          <div className="etx-button__text Button__text">
                            <span>Download Metamask</span>
                          </div>
                        </a>
                      </div>

                      <div className="etx-spacer h-50"></div>

                      <FaqAccordion
                        title="I’m a MetaMask Extension user. How do I use the same
                              wallet in MetaMask Mobile?"
                      >
                        <div className="etx-text Text">
                          <p>
                            You can use the same MetaMask wallet on both Mobile and
                            Extension. Simply install MetaMask and import your
                            existing wallet using your Secret Recovery Phrase.
                            Importing an existing wallet into a new installation of
                            the MetaMask extension and/or app is one of the only
                            times you’ll ever need to input your Secret Recovery
                            Phrase.
                          </p>
                          <p>
                            If any application, website, or extension unexpectedly
                            asks you for your Secret Recovery Phrase,{" "}
                            <b>
                              do not input it! You could lose all your funds!
                            </b>
                          </p>
                          <p>
                            Always make sure to only write your Secret Recovery
                            Phrase into a MetaMask installation you supervised, and
                            always check the URL in the browser if you're on a
                            computer.
                          </p>
                        </div>
                      </FaqAccordion>

                      <div className="etx-spacer h-25"></div>

                      <FaqAccordion title="How do I get funds (digital currency) into MetaMask?">
                        <div className="etx-text Text">
                          <p>
                            You can easily buy tokens directly within MetaMask by
                            clicking the blue “Buy & Sell” button. Different
                            networks and payment methods are available, though your
                            options will depend on your region. You can also request
                            funds from a friend by sending them a payment request,
                            showing your QR code in person, or by sharing your
                            public address.
                          </p>
                          <p>
                            There's also the option of swapping fiat currencies
                            such as{" "}
                            <a
                              href="https://www.kraken.com/convert/gbp/usdc"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              GBP for USDC on Kraken
                            </a>
                            {" "}or{" "}
                            <a
                              href="https://www.coinbase.com/en-gb/converter/usdc/usd"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              USD for USDC on Coinbase
                            </a>
                            {" "}and withdrawing USDC to your self-custodial
                            MetaMask wallet.
                          </p>
                        </div>
                      </FaqAccordion>

                      <div className="etx-spacer h-25"></div>

                      <FaqAccordion title="How do I keep my wallet safe?">
                        <div className="etx-text Text">
                          <p>
                            Backup your Secret Recovery Phrase. MetaMask requires
                            that you store your Secret Recovery Phrase in a safe
                            place. It is the only way to recover your funds should
                            your device crash, or your browser reset. We recommend
                            you write it down on paper or engraved Metal Plate and
                            safely store it in a couple secure locations.
                          </p>
                          <p>
                            There are many great options out there. Since your
                            MetaMask wallet is self-custodial, you're fully in
                            control.
                          </p>
                          <p>
                            The safe storage and security of your Secret Recovery
                            Phrase cannot be overstated. <b>Anyone with your Secret
                              Recovery Phrase has complete control over all funds
                              inside. If you lose your Secret Recovery Phrase, you
                              will lose access those funds
                              forever. MetaMask can't help you recover your
                              wallet.</b> Never give your Secret Recovery Phrase or
                            your private keys to anyone unless you want
                            them to have complete control over the funds inside.
                            Never use MetaMask on a shared or public device.
                          </p>
                        </div>
                      </FaqAccordion>

                      <div className="etx-spacer h-50"></div>

                      <div className="etx-video Video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/YVgfHZMFFFQ"
                          title="What is Metamask?"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </>
                  </FaqAccordion>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title={`How do I add the ${network.name} blockchain?`}>
                    <>
                      <div className="etx-text Text">
                        <p>
                          Once set up with your Metamask or other Web3 Wallet, we
                          need to add the {network.name} blockchain because Trendespresso is
                          hosted on the {network.name} blockchain. Log in to Metamask;
                          after that, click the network drop-down and click on
                          "Custom RPC". The video still uses the previous value;
                          Please ensure the "Explorer" is set to
                          "{network.blockExplorerURL}".
                        </p>
                        <p><i>Copy and paste the following information:</i></p>
                        <b>Network Name:</b> {network.name} <br />
                        <b>RPC URL:</b> {network.rpcURL}
                        <br />
                        <b>ChainID:</b> {Number(network.chainId)} <br />
                        <b>Symbol:</b> {network.nativeCurrency.name} <br />
                        <b>Explorer:</b> {network.blockExplorerURL}
                        <br />
                      </div>

                      <div className="etx-spacer h-25"></div>

                      <div className="etx-video Video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/KOgux1_2dFY"
                          title={`How to add the ${network.name} blockchain?`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </>
                  </FaqAccordion>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="How do I add my Tokens to Metamask?">
                    <>
                      <div className="etx-text Text">
                        <p>
                          Connect to the {network.name} blockchain. Then add the TE and USDC tokens:
                        </p>
                        <p>Open your MetaMask by clicking the fox icon (or other
                          Web3 wallet by clicking its icon), and select the Tokens
                          tab on the left side. Scroll down and click "Import tokens".
                          Search for USDC (not USDC.e) and import it. Then click
                          "Import tokens" again, select "Custom token" and input the
                          TE address below.
                        </p>

                        <p>
                          <b>TE:</b>&nbsp;
                          <a
                            href={`${network.blockExplorerForTokenURL}${TE_ADDR}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {TE_ADDR}
                          </a>
                          <br />
                          <b>USDC:</b>&nbsp;
                          <a
                            href={`${network.blockExplorerForTokenURL}${network.USDC}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {network.USDC}
                          </a>
                        </p>
                        <p>
                          You can also add TE and USDC to your MetaMask by clicking
                          the above links, clicking the button with three vertical
                          dots, and clicking the "Add token to Web3 Wallet" button.
                        </p>
                      </div>

                      <div className="etx-spacer h-25"></div>
                      <div className="etx-flex__item Flex__item">
                        <a
                          href={`${network.blockExplorerForTokenURL}${TE_ADDR}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img className="imgCurvedEdges" src={IMG_Add_token_to_Web3_wallet} />
                        </a>
                        {/* <img src="https://1125978081-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F5paB046T2jqW1Z9p0IAf%2Fuploads%2FYWKTe58tIcNGH2zWrk3Y%2Fimage.png?alt=media&token=b1af3e36-8cf5-4673-9282-354c3d899868" /> */}
                      </div>

                      <div className="etx-spacer h-25"></div>

                      <div className="etx-video Video">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/DM10ZbU8_MI"
                          title="Add Tokens"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </>
                  </FaqAccordion>

                  <div className="etx-spacer h-25" id="MetaMask_Simulation_Anchor"></div>
                  <div
                    // onClick={(e) => (e.target as any).scrollIntoView(true, { behavior: "smooth", block: "start" })}

                    onClick={() => scrollToJustAbove("MetaMask_Simulation_Anchor")}
                  >
                    <FaqAccordion title="I selected a different wallet in MetaMask but it's not connected!">
                      <div className="etx-text Text">
                        <div style={{ textAlign: "center" }}>
                          <b>
                            Open MetaMask again and click the blue <span style={{ color: "#0476c9" }}>"Connect"</span> link.
                          </b>
                        </div>
                        <br />
                        <img
                          className="imgCurvedEdges"
                          src={IMG_MetaMask_Blue_Connect_Link}
                          style={{ width: "50%", margin: "auto" }}
                        />
                        <br />
                        <p>
                          <a
                            href={metaMaskSimulationURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Try out the MetaMask simulation (scroll down)
                          </a>
                          {" "}to learn more.
                        </p>
                        <p>
                          <iframe
                            id="MetaMask_Simulation"
                            src={metaMaskSimulationURL}
                            onLoad={() => {
                              const metaMaskSimulation = document.getElementById("MetaMask_Simulation");
                              console.log("metaMaskSimulation 1");
                              console.log(metaMaskSimulation);
                              if (metaMaskSimulation) {
                                setTimeout(() => metaMaskSimulation.scrollTop = 100, 5000);
                                console.log("metaMaskSimulation 2");
                                console.log(metaMaskSimulation);
                              }
                            }}
                            style={{ width: "100%", height: "50vw" }}
                          >
                            { }
                          </iframe>
                        </p>
                        <p>
                          MetaMask and other Web3 wallet providers are not always intuitive.
                        </p>
                        <p>
                          After you switch the selected address in your MetaMask, you then
                          need to connect that selected address to the website. By default,
                          if you open MetaMask again, you'll be prompted to Connect your
                          selected wallet to the current webpage in your browser if the
                          webpage is Web3-enabled and your selected wallet is not presently
                          connected.
                        </p>
                        <p>
                          Our suggestion is thus:<br />
                          ☕️ Open MetaMask<br />
                          ☕️ Select your preferred address<br />
                          ☕️ Click outside of MetaMask<br />
                          ☕️ Then, open MetaMask again and click the blue <span style={{ color: "#0476c9" }}>"Connect"</span> link.
                        </p>
                        <br />
                        {/* <img
                        className="imgCurvedEdges"
                        src={IMG_MetaMask_Blue_Connect_Link_Demo}
                        style={{ width: "50%", margin: "auto" }}
                      /> */}
                        <video autoPlay loop className="imgCurvedEdges" style={{ width: "50%", marginLeft: "25%" }}>
                          <source src={IMG_MetaMask_Blue_Connect_Link_Demo_WEBM} type="video/webm" />
                          <source src={IMG_MetaMask_Blue_Connect_Link_Demo_MOV} type="video/mp4" />
                          Sorry, your browser doesn't support videos.
                        </video>
                      </div>
                    </FaqAccordion>
                  </div>

                  <div className="etx-spacer h-50"></div>

                  <div className="etx-text Text appearAtNarrowWindowWidth">
                    <h5>Fully Managed Trading Service</h5>
                  </div>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="How does Trendespresso work?">
                    <div className="etx-text Text">
                      <p>
                        Our automated trading strategy, based on TradingView and our Trading
                        Engine to dispatch trades securely via smart contracts on
                        the {network.name} blockchain. Every trade is logged for a
                        transparent transaction history. Our system is designed
                        for secure, on-chain trading; it doesn't rely on our
                        website or public servers.
                      </p>
                    </div>
                    {/* <div className="etx-spacer h-25"></div>
                    <div className="etx-flex__item Flex__item">
                      <img className="imgCurvedEdges" src="https://1125978081-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F5paB046T2jqW1Z9p0IAf%2Fuploads%2Fgit-blob-c3f31d7cb7ee20f68ceb9d2f0de68825b7273ad1%2Fsystemmap.png?alt=media" />
                    </div> */}
                  </FaqAccordion>

                  {/* <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="What is the Operation Deposit? (Starting with Beta)">
                    <div className="etx-text Text">
                      <b>
                        The Operation Deposit will get implemented with the Beta
                        Release of our Bot.
                      </b>
                      <p>
                        To encourage participation in TE tokennomics, we're
                        implementing a 10% Operating Deposit in TE Tokens, based
                        on your USDC deposit value. For example, if you deposit 10
                        USDC, an additional 1 USDC worth of TE Tokens is required.
                        This deposit won't be used for USDC Trade Fee nor accrue
                        TCC farm rewards, only for when TE is used as the trade
                        fee. Don't worry, the bot manages this automatically, and
                        the bot website will provide detailed breakdowns. This
                        mechanism is designed to attract large-scale investors
                        while keeping Trading Fees affordable for users globally,
                        ensuring equitable access. When withdrawing, your
                        Operating Deposit reduces proportionally to the cash
                        balance withdrawn, enabling you to start earning TCC farm
                        rewards. For instance, if you withdraw 10% of your USDC
                        deposit, 10% of your Operating Deposit is released. The
                        option to withdraw the full cash balance and entire TE
                        Operating Deposit in the same transaction is also
                        available. As always, you're in control.
                      </p>
                    </div>
                  </FaqAccordion> */}

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="Two strategy modes: Individual vs Pooled">
                    <div className="etx-text Text">
                      <p>
                        <b>Individual mode</b> deploys a smart contract unique to
                        you when you join a strategy. All smart contracts under
                        a given strategy receive the same signals and are thus
                        traded the same way. Importantly however, all of your
                        funds under Individual mode are fully segregated from all
                        other strategy participants, ensuring the full integrity
                        of your balance and guaranteeing the highest level of
                        security.
                      </p>
                      <p>
                        <b>Pooled mode</b> sees you join an already existing smart
                        contract with other strategy participants. Pooled smart
                        contracts receive the same signals as their Individual
                        counterparts but offer a much lower bar of entry. Whereas
                        Individual mode requires thousands of USDC to join and
                        incurs a high upfront gas cost, Pooled mode can be joined
                        with as little as 100 USDC and less than 1 USDC worth
                        of gas.
                      </p>
                      <div className="etx-spacer h-25"></div>
                      <div className="etx-flex__item Flex__item">
                        <a
                          href="https://youtu.be/3zFYAvayGyk?feature=shared&t=361"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img className="imgCurvedEdges15px" src={IMG_Individual_vs_Pooled} />
                          {/* <img src="https://1125978081-files.gitbook.io/~/files/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F5paB046T2jqW1Z9p0IAf%2Fuploads%2FYWKTe58tIcNGH2zWrk3Y%2Fimage.png?alt=media&token=b1af3e36-8cf5-4673-9282-354c3d899868" /> */}
                        </a>
                      </div>
                    </div>
                  </FaqAccordion>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="What are the Trading Fees?">
                    <div className="etx-text Text">
                      <b>
                        There are two fees on our platform:<br />
                        1) Monthly subscription charge in <span style={{ fontSize: "0.95em" }}>Ŧ</span>E<br />
                        2) Profit share in USDC
                      </b>
                      <p>
                        Both fees are strategy-specific. Select a
                        strategy and then check the right side panel for
                        verification of that strategy's fees.
                      </p>
                      <p>
                        The monthly subscription is charged in{" "}
                        <span style={{ fontSize: "0.95em" }}>Ŧ</span>E. Typically,
                        strategies you join in Individual mode will have a fee
                        equivalent to 20 USDC per month and strategies you join
                        in Pooled mode will  have a fee equivalent to 10 USDC
                        per month.
                      </p>
                      <p>
                        The profit share is paid only on trades that conclude
                        profitably and is our primary income stream. Typically
                        the profit share is 20% and can be reduced by having
                        greater <span style={{ fontSize: "0.95em" }}>Ŧ</span>E
                        deposited in Trendespresso.
                      </p>
                      <p>
                        <span style={{ fontSize: "0.95em" }}>Ŧ</span>E can be{" "}
                        <a
                          href="/swap?from=USDC&to=TE"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          obtained on the Swap page
                        </a>
                        .
                      </p>
                    </div>
                  </FaqAccordion>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="What is the Profit Share?">
                    <div className="etx-text Text">
                      <b>The profit-sharing fee is charged in USDC.</b>
                      <p>
                        Profit sharing is only charged on profits earned and
                        occurs as soon as the trade exits.
                      </p>
                    </div>
                  </FaqAccordion>

                  <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="I can't deposit or withdraw!">
                    <div className="etx-text Text">
                      <p>
                        <b>Make sure you have a Web3 wallet – such as MetaMask – installed.</b>
                      </p>
                      <p>
                        <b>Make sure you're connected to the {network.name} blockchain.</b>
                      </p>
                      <p>
                        Then try refreshing the page. Try using a different browser,
                        using Private or Incognito mode, and clearing your cache.
                        Try a different device after exhausting the above.
                      </p>
                      {/* <p>
                        Profit sharing is only charged on profits earned and
                        occurs as soon as the trade exits. The current
                        profit-sharing rate is 10.0%. NFT holders benefit by owing
                        a reduced rate. The higher your NFT level, the lower your
                        profit-sharing rate. Reductions do not stack; only the
                        highest level applies (most significant reduction). When
                        the Bot exits a trade, the profit share is automatically
                        deducted and sent to the multi-sig treasury wallet to
                        ensure the bot's continued operation and development.
                      </p> */}
                    </div>
                  </FaqAccordion>

                  {/* <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="How do I deposit USDC?">
                    <div className="etx-text Text">
                      <b>
                        To deposit USDC or TE into our non-custodial trading service:
                      </b>
                      <p>
                        Begin by identifying the panel titled "Your Bot Funds" on
                        the main interface and proceed by clicking the "Deposit"
                        button. Upon doing so, a pop-up window will materialize
                        prompting you to input the specific amount of USDC you
                        wish to deposit. After entering the desired quantity,
                        proceed by clicking the "Deposit" button, which can be
                        recognized by its distinct teal color. Following this
                        step, a prompt will appear for you to verify and confirm
                        the transaction details in your wallet. After the
                        transaction is successfully processed and confirmed, sit
                        back, relax, and enjoy watching your potential financial
                        gains accumulate.
                      </p>
                    </div>
                  </FaqAccordion> */}

                  {/* <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="How do I withdraw my assets?">
                    <div className="etx-text Text">
                      <b>
                        To withdraw USDC or TE from our non-custodial trading service:
                      </b>
                      <p>
                        Our decentralized Trading Bot allows for four types of
                        withdrawals—withdraw(), withdrawNow(), withdrawAll(), and
                        withdrawAllNow(). These cater to specific partial or full
                        withdrawals, either instantly or pending. You can call
                        these functions anytime except for withdrawNow(), which
                        only works during trades. During Alpha 2.5, your main
                        concern is withdrawing your USDC as desired. Withdraw()
                        and withdrawAll() process pending withdrawals when the bot
                        is "Holding Cash." Meanwhile, withdrawNow() and
                        withdrawAllNow() execute instant withdrawals during
                        trades. Beware of penalties with instant withdrawals,
                        which are softened depending on your owned NFT. Ensure you
                        maintain a minimum cash balance to prevent involuntary
                        conversion to a full withdrawal. An Immediate Withdrawal
                        Penalty of 50.0% of the trade's profits exists to
                        discourage instant withdrawals during profit loss,
                        reducible by owning an NFT. If your cash balance falls
                        short after withdrawal, it will be converted to a full
                        withdrawal. The selection of withdrawal function is
                        automated on our interface based on your input and button
                        choice—"Withdraw" or "Withdraw Now".
                      </p>
                    </div>
                  </FaqAccordion> */}

                  {/* <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="What is the TCC Farm Reward?">
                    <div className="etx-text Text">
                      <p>
                        Our decentralized Trading Bot incentivizes use through
                        Farm Rewards. By depositing TE, you earn TCC Tokens.
                        Owning an NFT enhances these earnings. The reward rate is
                        currently 21.0% per annum. On the website, a 1x multiplier
                        means you're earning at this rate, while 2x signifies
                        42.0%. When you deposit TE, you start earning at 1x.
                        Depositing cash raises this to 2x. Holding an NFT further
                        increases this to: 2.25x for Silver, 2.50x for Gold, 2.75x
                        for Legendary, and 3.00x for Insane holders. Weekly
                        rewards can be calculated by dividing the current rate by
                        100 and 52, then multiplying by your deposited TE.
                      </p>
                    </div>
                  </FaqAccordion> */}

                  {/* <div className="etx-spacer h-25"></div>

                  <FaqAccordion title="What Bonus do I get with my NFTs?">
                    <div className="etx-text Text">
                      <p>
                        Our limited NFT Collection offers a significant boost to
                        your bot experience, demonstrating that NFT technology
                        goes beyond just art in the crypto space. These NFTs
                        provide tangible benefits and savings: Silver holders
                        enjoy a 25% reduction, Gold holders 50%, Legendary holders
                        75%, and Insane holders reap a full 100% reduction on
                        certain features. These reductions apply to various
                        use-cases including reductions of fees and the Immediate
                        Withdrawal Penalty, as well as increasing earning rates.
                        For instance, when cash is deposited into the bot,
                        multipliers increase based on the NFT held: 2.25x for
                        Silver, 2.5x for Gold, 2.75x for Legendary, and an
                        impressive 3.0x for Insane holders. NFT owners thus
                        benefit from lower operating costs, decreased immediate
                        withdrawal penalties, and bonus TCC earnings for cash
                        deposited into the bot. Our NFTs offer real-world savings
                        and valuable use-cases. As we say, Das güd.
                      </p>
                    </div>
                  </FaqAccordion> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
